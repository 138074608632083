import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

function BasicExample() {
  const [sortedClientiData, setSortedClientiData] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get('./dati_dashboard.json')
        .then((res) => {
          const clientiData = res.data.SUD.clienti || {};

          const transformedData = Object.entries(clientiData).map(
            ([cliente, items]) => {
              let totalValoreWeb = 0;
              let totalValoreNonWeb = 0;
              let totalNumeroWeb = 0;
              let totalNumeroNonWeb = 0;

              items.forEach((item) => {
                if (item.origine === 'web') {
                  totalValoreWeb += parseFloat(item.valore);
                  totalNumeroWeb += parseInt(item.numero);
                } else {
                  totalValoreNonWeb += parseFloat(item.valore);
                  totalNumeroNonWeb += parseInt(item.numero);
                }
              });

              return {
                cliente,
                valore: (totalValoreWeb + totalValoreNonWeb).toFixed(2),
                numero: totalNumeroWeb + totalNumeroNonWeb,
                origine: `${totalValoreWeb > 0 ? 'web ' : ''}${
                  totalValoreNonWeb > 0 ? 'non web' : ''
                }`.trim(),
              };
            }
          );

          const sortedData = transformedData.sort(
            (a, b) => parseFloat(b.valore) - parseFloat(a.valore)
          );
          setSortedClientiData(sortedData);
        })
        .catch((err) => {
          alert(err);
          setIsError(!isError);
          setError({ error });
        });
    };

    fetchData();
  }, [isError]);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  return (
    <div className='bg-light widget-client2 ombra tb hover-effect' style={{borderRadius: '25px'}}>
      <Table className='tb' striped hover style={{ fontSize: '0.8rem' }}>
        <thead>
          <tr>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Clienti</th>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Valore</th>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Numero</th>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Origine</th>
          </tr>
        </thead>
        <tbody>
          {sortedClientiData.map((clientiItem, index) => (
            <tr key={index}>
              <td>{clientiItem.cliente}</td>
              <td>{formatToEuro(clientiItem.valore)}</td>
              <td>{clientiItem.numero}</td>
              <td>{clientiItem.origine}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default BasicExample;
