import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/TP-_logo-orizz-claim-color-white-text.png";
import logoInvert from "../images/sud-ricambi-logo-symbol-white.png";
import logoInvert2 from "../images/amotive-logo-symbol-inverted-cut.png";
import logoInvert3 from "../images/logo_proforma-removebg-preview (1).png";
import { IoIosArrowForward } from "react-icons/io";
import { FaUnlock } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";

function BasicExample() {
  const [isLocked, setIsLocked] = useState(() => {
    const lockedState = localStorage.getItem("isLocked");
    return lockedState ? JSON.parse(lockedState) : true;
  });

  const handleLockClick = () => {
    setIsLocked(!isLocked);
  };

  useEffect(() => {
    localStorage.setItem("isLocked", JSON.stringify(isLocked));
  }, [isLocked]);

  
  useEffect(() => {
    let intervalId;

    if (!isLocked) {
      intervalId = setInterval(() => {
        window.location.reload();
      }, 150000);
    } else {
      intervalId = setInterval(() => {
        window.location.href = "/amotive";
      }, 150000);
    }

    return () => clearInterval(intervalId);
  }, [isLocked]);

  return (
    <Navbar
      expand="lg"
      className="full-width-navbar shadow p-0 sticky-navbar navbar-heigth"
      style={{
        background:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(121,9,9,1) 100%",
        maxHeight: "50px",
      }}
    >
      <Container fluid>
        <Navbar.Brand href="#home" className="fs-3 fw-bold mx-3">
          <img
            alt=""
            src={logo}
            width=""
            height=""
            className="d-inline-block align-middle logo"
            style={{ maxWidth: "150px" }}
          />
        </Navbar.Brand>
   

        <div className="d-flex align-items-center">
         

     


            <Link to={"/sud-ricambi"}>
            <Navbar.Brand href="#sud-ricambi" className="fw-bold mx-3 logo-mobile">
              <img
                alt=""
                src={logoInvert}
                width=""
                height=""
                className="d-inline-block align-middle mb-1 "
                style={{ maxWidth: "45px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>{" "}
          </Link>


          <Link to={"/amotive"}>
            {" "}
            <Navbar.Brand href="#amotive" className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvert2}
                width=""
                height=""
                className="d-inline-block align-middle mb-1 logo"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
              
            </Navbar.Brand>{" "}
           
            
          </Link>
          <div class="toggle-button-cover mt-2">
            <div id="button-3" class="button r">
              <input
                class="checkbox"
                type="checkbox"
                checked={isLocked}   uiul
                onChange={handleLockClick}
              />
              <div class="knobs"></div>
              <div class="layer"></div>
              <span className="lock-icon" isLocked={isLocked}>
                {isLocked ? (
                  <FaUnlock className="text-dark fs-6" />
                ) : (
                  <FaLock className="text-dark fs-6" />
                )}
              </span>
            </div>
          </div>
          <Link to={"/proforma"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvert3}
                width=""
                height=""
                className="d-inline-block align-middle mb-1 logo"
                style={{ maxWidth: "40px" }}
              />            
            </Navbar.Brand>
            </Link>
        </div>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
