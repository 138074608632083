import Navbar from "./amotive/NavbarTop";
import CardSell from "./amotive/CardSell";
import CardSell2 from "./amotive/CardSell2";
import CardSell3 from "./amotive/CardSell3";
import CardSell4 from "./amotive/CardSell4";
import AreaChart from "./amotive/AreaChart";
import WorldMaps from "./amotive/WorldMaps";
import BarChart from "./amotive/BarChart";
import BarChartArIt from "./amotive/BarChartArIt";
import GraphicDay from "./amotive/GraphicDay";
import GraphicWeek from "./amotive/GraphicWeek";
import TabsClient from "./amotive/TabsClient";
import TabsTipoCli from "./amotive/TabsTipoCli";
import FooterMobileAr from "./amotive/FooterMobileAr";
import GoogleMap from "./amotive/GoogleMap";
import Dettagli from "./amotive/DettagliManVenAR";
import React, { useState, useEffect } from "react";



function Home() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const apikey = 'AIzaSyD16h98hJe26HkkEvzKPbH2WytgT5srggM';

  return (
    <div className="main-container">
      <Navbar />
      <div className="amotive">
        <GraphicDay />
        <GraphicWeek />
        <GoogleMap apiKey={apikey} />
        <div className="card-container">
          <CardSell3 />
          <CardSell2 />
          <Dettagli showModal={showModal} handleCloseModal={handleCloseModal}  />
        </div>
        <TabsClient />
        <TabsTipoCli />
        <AreaChart />
        <BarChart />
        <BarChartArIt />
      </div>
      <FooterMobileAr />
    </div>
  );
}

export default Home;
