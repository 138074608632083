import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Card } from "react-bootstrap";

function CityBarChart() {
  const [citiesData, setCitiesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("./dati_dashboard.json");
        const data = await response.json();

        const transformedData = Object.keys(data.SUD.paesi).map((key) => {
          const countryData = data.SUD.paesi[key];
          const totalValore = countryData.reduce(
            (acc, curr) => acc + parseFloat(curr.valore),
            0
          );
          const totalNumero = countryData.reduce(
            (acc, curr) => acc + parseInt(curr.numero),
            0
          );

          return {
            nazione: key,
            valore: totalValore.toFixed(2),
            numero: totalNumero,
          };
        });

        transformedData.sort((a, b) => b.numero - a.numero);

        const firstCities = transformedData.slice(0, 5);
        console.log(firstCities);
        setCitiesData(firstCities);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  return (
    <Card className=" ordini-ora card-home border-light hover-effect">
      <div className="mt-1 mx-auto">
        <p className="border border-danger text-title3 p-1">ORDINI RICEVUTI</p>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          className=""
          data={citiesData}
          layout="vertical"
          margin={{
            top: 0,
            right: 75,
            left: 75,
            bottom: 0,
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="numero" />
          <YAxis dataKey="nazione" type="category" hide="true" />
          <Bar dataKey="numero" fill="hsl(0, 0%, 89%)" name="Numero di Persone">
            <LabelList
              dataKey="valore"
              formatter={(value) => formatToEuro(parseFloat(value))}
              position="left"
              fill="black"
              className="fw-bold"
              style={{ fontSize: "0.7em" }}
            />
            <LabelList
              dataKey="nazione"
              position="insideLeft"
              fill="black"
              className="fw-bold"
              style={{ fontSize: "0.6em" }}
            />
            <LabelList
              dataKey="numero"
              position="insideRight"
              fill="black"
              className="fw-bold"
              style={{ fontSize: "0.6em" }}
            />
          </Bar>
          <Tooltip />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default CityBarChart;
