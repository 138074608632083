import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import axios from "axios";

function WithHeaderStyledExample() {
  const [totalOrdered, setTotalOrdered] = useState(0);
  const [averageTotal, setAverageTotal] = useState(0);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [totalLastMinute, setTotalLastMinute] = useState(0);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get('./dati_dashboard.json')
        .then((res) => {
          const { totale_ordinato, totale_lastminute, origine } = res.data.AR;
  
          console.log("Total Last Minute:", totale_lastminute); // Log the value
  

          if (totale_ordinato && parseFloat(totale_ordinato) > 0) {
            setTotalOrdered(parseFloat(totale_ordinato));
          }

          if (totale_lastminute && parseFloat(totale_lastminute) > 0) {
            setTotalLastMinute(parseFloat(totale_lastminute));
          }

          if (origine && origine.length > 0) {
            const webOrigin = origine.find((item) => item.origine === "web");
            const nowebOrigin = origine.find(
              (item) => item.origine === "noweb"
            );

            let webAverage = 0;
            let nowebAverage = 0;

            if (webOrigin) {
              webAverage = parseFloat(webOrigin.valore_medio);
            }

            if (nowebOrigin) {
              nowebAverage = parseFloat(nowebOrigin.valore_medio);
            }

            const totalAverage = webAverage + nowebAverage;
            setAverageTotal(totalAverage);
          }
        })
        .catch((err) => {
          alert(err);
          setIsError(!isError);
          setError({ error });
        });
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  return (
    <Card
      className="rounded border-transparent cs hover-effect"
      style={{
        boxShadow: "2px 2px 10px 2px gray",
        border: "none",
      }}
    >
      <Card.Body
        className=" ombra d-flex flex-column align-items-center justify-content-center text-center"
        style={{ backgroundColor: "rgba(241,111,36,1)" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">


        {totalLastMinute && (
            <p
              className="text-light mt-2"
              style={{ fontSize: "0.8em", margin: 0 }}
            >
              Total Last Minute: {formatToEuro(totalLastMinute)}
            </p>
          )}






          <h3 className="text-light ">{formatToEuro(totalOrdered)}</h3>
          {totalOrdered !== 0 && (
            <p
              className="text-light mt-2"
              style={{ fontSize: "0.8em", margin: 0 }}
            >
              {(((totalOrdered - averageTotal) / averageTotal) * 100).toFixed(
                2
              )}
              %{" "}
              {totalOrdered - averageTotal < 0 ? (
                <FaArrowTrendDown style={{ color: "red" }} />
              ) : (
                <FaArrowTrendUp style={{ color: "green" }} />
              )}
            </p>
          )}
          
        </div>
        {averageTotal !== 0 && (
          <>
            <p className="text-light" style={{ fontSize: "0.8em", margin: 0 }}>
              Confronto media mensile: {formatToEuro(averageTotal)}
            </p>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default WithHeaderStyledExample;
