import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import axios from "axios";

const SalesChart = () => {
  const [weekData, setWeekData] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("./dati_dashboard.json")
        .then((res) => {
          const weekOrdersData = res.data.AR.settimana;

          const sortedWeekData = weekOrdersData.sort(
            (a, b) => a.ordine_giorno - b.ordine_giorno
          );

          setWeekData(sortedWeekData);
        })
        .catch((err) => {
          alert(err);
          setIsError(!isError);
          setError({ err });
        });
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const maxYValue =
    Math.max(...weekData.map((dataPoint) => dataPoint.valore_ordinato)) + 1000;

  const today = new Date().getDay();
  const currentWeekDays = weekData.map((dataPoint) => dataPoint.it_nome_giorno);
  const opacityValues = currentWeekDays.map((day, index) =>
    index < today ? 1 : 0.3
  );

  return (
    <Card className="ombra border-light card-week card-home rounded-0 bg-dark hover-effect">
      <div className="mt-2 mx-auto">
        <p className="border border-danger text-title2 p-1 rounded">
          ORDINI RICEVUTI
        </p>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={weekData}
          margin={{
            top: 5,
            right: 75,
            left: 75,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            domain={[0, maxYValue]}
            tickFormatter={(value) =>
              `${parseFloat(value).toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            }
          />
          <XAxis dataKey="it_nome_giorno" />
          <Tooltip
            formatter={(value, name) => [
              `${parseFloat(value).toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
              name,
            ]}
          />
          <Legend />
          <Bar dataKey="valore_ordinato" name="Valore Ordinato" fill="grey">
            {weekData.map((entry, index) => (
              <Cell key={`cell-${index}`} fillOpacity={opacityValues[index]} />
            ))}
          </Bar>
          <Bar
            dataKey="media_valore"
            name="Media Valore"
            fill="rgba(241,111,36,1)"
          >
            {weekData.map((entry, index) => (
              <Cell key={`cell-${index}`} fillOpacity={opacityValues[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SalesChart;
