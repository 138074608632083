import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

const SalesChart = () => {
  const [brandData, setBrandData] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("./dati_dashboard.json")
        .then((res) => {
          const orarioBrandData = res.data.AR.orario_brand;
          const brandSales = prepareDataForChart(orarioBrandData);
          setBrandData(brandSales);
        })
        .catch((err) => {
          alert(err);
          setIsError(!isError);
          setError({ error });
        });
    };

    fetchData();
  }, []);

  const prepareDataForChart = (orarioBrandData) => {
    const brandSales = {};
  
    orarioBrandData.forEach((item) => {
      const { descrizione, valore } = item;
  
      if (!brandSales[descrizione]) {
        brandSales[descrizione] = parseFloat(valore);
      } else {
        brandSales[descrizione] += parseFloat(valore);
      }
    });
  
    // Ordina i dati in base al valore dalla più alta alla più bassa
    const sortedBrandSales = Object.entries(brandSales)
      .map(([brand, value]) => ({ brand, value }))
      .sort((a, b) => b.value - a.value);
  
    return sortedBrandSales;
  };

  return (
    <Card
      className="ombra card-week card-home bg-dark rounded-0 hover-effect"
    >
      <div className="mt-2 mx-auto">
      <h5 className="border border-danger text-title2 p-1 rounded">
          ORDINI BRAND 
        </h5>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={730}
          height={170}
          data={brandData}
          margin={{ top: 0, right: 75, left: 75, bottom: 0 }}
          className="gd"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="brand" />
          <YAxis />
          <Tooltip
            formatter={(value) =>
              `${parseFloat(value).toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            }
          />
          <Legend wrapperStyle={{ fontSize: "8px" }} />
          <Bar dataKey="value" fill="rgba(241,111,36,1)" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SalesChart;
