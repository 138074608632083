import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import logo from "../images/amotive-orange-revert-white-orange-symbol.png";

function BasicExample() {
  const [sortedClientiData, setSortedClientiData] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "valore",
    direction: "descending",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("mancata_vendita_dashboard.json");

        // Log dell'intero oggetto ricevuto per capire la struttura
        console.log(res.data);

        // Verifica la presenza di AR e localita e che localita sia un array
        if (res.data && res.data.AR && Array.isArray(res.data.AR.localita)) {
          const clientiData = res.data.AR.localita;

          // Raggruppa per id_cliente e somma i valori
          const clientiAggregati = clientiData.reduce((acc, item) => {
            const existingClient = acc.find(
              (c) => c.id_cliente === item.id_cliente
            );
            const valoreNumerico = parseFloat(item.Valore.replace(",", "."));

            if (existingClient) {
              existingClient.valore += valoreNumerico;
              existingClient.dettagli.push({
                brand: item.brand,
                precodice: item.precodice,
                valore: valoreNumerico,
              });
            } else {
              acc.push({
                id_cliente: item.id_cliente,
                cliente: item.clienti,
                valore: valoreNumerico,
                numero: item.numero_doc,
                localita: item.regione || item.nazione,
                origine: item.origine,
                dettagli: [
                  { precodice: item.precodice, brand: item.brand, valore: valoreNumerico },
                ],
              });
            }

            return acc;
          }, []);

          // Ordina i dati inizialmente in base alla configurazione di ordinamento
          const sortedData = sortArray(
            clientiAggregati,
            sortConfig.key,
            sortConfig.direction
          );
          setSortedClientiData(sortedData);
        } else {
          // Gestione del caso in cui localita non è un array o manca
          setError(
            "Dati delle località non disponibili o in formato non corretto."
          );
          setSortedClientiData([]);
        }
      } catch (err) {
        console.error("Errore durante il fetch dei dati:", err);
        setIsError(true);
        setError(err.message);
      }
    };

    fetchData();
  }, [sortConfig]);

  const sortArray = (array, key, direction) => {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = "clienti.xlsx";
    const data = sortedClientiData.map((item) => ({
      Cliente: item.cliente,
      Valore: formatToEuro(item.valore), // Formatta il valore per l'esportazione
      Numero: item.numero,
      Località: item.localita,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, fileName);
  };

  const handleClienteClick = (cliente) => {
    setSelectedCliente(cliente);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCliente(null);
  };

  

  return (
    <div
      className="bg-secondary widget-client2 ombra tb hover-effect"
      style={{ borderRadius: "25px" }}
    >
      <Table className="bg-secondary" hover style={{ fontSize: "0.8rem" }}>
        <thead>
          <tr>
            <th
              style={{ backgroundColor: "rgba(241,111,36,1)" }}
              className="sticky-top"
            >
              Clienti
            </th>
            <th
              style={{
                backgroundColor: "rgba(241,111,36,1)",
                cursor: "pointer",
              }}
              className="sticky-top"
              onClick={() => handleSort("localita")}
            >
              Località
            </th>
            <th
              style={{
                backgroundColor: "rgba(241,111,36,1)",
                cursor: "pointer",
              }}
              className="sticky-top"
              onClick={() => handleSort("valore")}
            >
              Valore
            </th>
            <th
              style={{ backgroundColor: "rgba(241,111,36,1)" }}
              className="sticky-top"
            >
              Numero
            </th>
            <th
              style={{ backgroundColor: "rgba(241,111,36,1)" }}
              className="sticky-top"
            >
              Origine
            </th>
            <th
              className="bg-success text-light sticky-top"
              onClick={exportToExcel}
            >
              <SiMicrosoftexcel className="fs-5 excel" />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedClientiData.map((clientiItem, index) => (
            <tr key={index} onClick={() => handleClienteClick(clientiItem)}>
              <td className="bg-secondary text-light">{clientiItem.cliente}</td>
              <td className="bg-secondary text-light">
                {clientiItem.localita}
              </td>
              <td className="bg-secondary text-light">
                {formatToEuro(clientiItem.valore)}
              </td>
              <td className="bg-secondary text-light">{clientiItem.numero}</td>
              <td className="bg-secondary text-light">{clientiItem.origine}</td>
              <td className="bg-secondary text-light"></td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modale per mostrare i dettagli del cliente */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header className="bg-dark rounded-0">
          <Modal.Title className="text-modal2AR mx-auto bg-transparent border border-light">
          {selectedCliente?.cliente}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          {selectedCliente?.dettagli.map((dettaglio, dettaglioIndex) => (
            <p className="text-center fs-4" key={dettaglioIndex}>
             <span className="fw-bold">{dettaglio.brand}: </span>{" "}
             {formatToEuro(dettaglio.valore)}
            </p>
          ))}
        </Modal.Body>
        <Modal.Footer className="bg-dark rounded-0">
          {" "}
          <img
            src={logo}
            alt=""
            className="mx-auto p-2"
            style={{ width: "180px" }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BasicExample;
