import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';

function BasicExample() {
  const [sortedTipo_cliData, setSortedTipo_cliData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("./dati_dashboard.json");
        const tipo_cliData = response.data?.SUD?.tipo_cli || [];

        const transformedData = tipo_cliData.map(item => ({
          tipo_cliente: item.tipo_cliente,
          valore: parseFloat(item.valore).toFixed(2),
          numero: parseInt(item.numero),
          origine: item.origine,
        }));

        const sortedData = transformedData.sort((a, b) =>
          parseFloat(b.valore) - parseFloat(a.valore)
        );

        setSortedTipo_cliData(sortedData);
      } catch (error) {
        console.error('Error fetching data from the server:', error);
      }
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  return (
    <div className='bg-light ombra widget-client2 tb hover-effect'  style={{borderRadius: '25px'}}>
      <Table striped  hover style={{fontSize: '0.8rem'}}>
        <thead >
          <tr>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Tipo Cliente</th>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Valore</th>
            <th style={{backgroundColor: 'red'}} className='text-light sticky-top'>Numero</th>
          </tr>
        </thead>
        <tbody>
          {sortedTipo_cliData.map((tipo_cliItem, index) => (
            <tr key={index}>
              <td>{tipo_cliItem.tipo_cliente}</td>
              <td>{formatToEuro(tipo_cliItem.valore)}</td>
              <td>{tipo_cliItem.numero}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}



export default BasicExample;
