import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <Form className="d-flex mt-4" onSubmit={handleSearch} style={{ outline: 'none' }}>
      <FormControl
        type="search"
        placeholder="Search"
        className="w-100 mx-4 me-0 border-light"
        value={query}
        onChange={handleInputChange}
        style={{
          borderRadius: '10px 0px 0px 10px',
          outline: 'none',
          boxShadow: 'none'
        }}
      />
      <Button
        style={{
          backgroundColor: 'rgba(121,9,9,1)',
          borderRadius: '0px 10px 10px 0px',
          outline: 'none',
          boxShadow: 'none'
        }}
        className="border-0 mx-0 form-search"
        type="submit"
      >
        <FaSearch />
      </Button>
    </Form>
  );
};

export default SearchBar;
