import axios from "axios";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import logo from "../images/TP-_logo-orizz-claim-color.png";

const Dettagli = ({ showModal, handleCloseModal }) => {
    const [citiesData, setCitiesData] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "ws/index.php?Command=google.getAnalitycs"
        );

        if (
          response.data &&
          response.data.TP &&
          response.data.TP.mappa &&
          response.data.TP.mappa.ecommerce
        ) {
          const ecommerceData = response.data.TP.mappa.ecommerce;

          const coordinatesArray = [];

          for (const cityData of ecommerceData) {
            try {
              if (cityData.descrizioneCity) {
                const response = await axios.get(
                  `https://nominatim.openstreetmap.org/search?format=json&q=${cityData.descrizioneCity}`
                );
                const coordinates = response.data[0];

                if (coordinates) {
                  coordinatesArray.push({
                    name: cityData.descrizioneCity,
                    position: [
                      parseFloat(coordinates.lat),
                      parseFloat(coordinates.lon),
                    ],
                    visits: parseFloat(cityData.activeUser),
                  });
                }
              }
            } catch (error) {
              console.error("Error retrieving coordinates:", error);
            }
          }

          setCitiesData(coordinatesArray);
        } else {
          console.error("Expected data structure not found in the response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      className="rounded-0 modale-test w-100"
    >
      <Modal.Header className="bg-white">
        <Modal.Title className="text-modal mx-auto bg-transparent bg-light border border-light">
          visite
          <br />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {citiesData.map((city, index) => (
            <ListGroup.Item
              key={index}
              style={{
                fontSize: "1.5em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {city.name}: {city.visits}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <img
          src={logo}
          alt=""
          className="mx-auto p-2"
          style={{ width: "200px" }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default Dettagli;
