import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import logo from "../images/TP-_logo-orizz-claim-color.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaList, FaTable } from "react-icons/fa";
import Card from 'react-bootstrap/Card';

const InfoModal = ({ show, handleClose, rowData }) => {
  useEffect(() => {
    if (show && rowData) {
      console.log("Modal aperto con dati:", rowData);
    }
  }, [show, rowData]);

  const formatToEuro = (value) => {
    if (typeof value === "number" || !isNaN(parseFloat(value))) {
      return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    }
    return value;
  };

  // Controllo se rowData esiste, altrimenti restituisco null o uno stato di caricamento
  if (!rowData) {
    return <p></p>;
  }

  return (
    <Modal show={show} onHide={handleClose} centered style={{height: '90vh', margin: '80px'}}>
      <Modal.Header className="bg-white">
        <Modal.Title className="text-modal mx-auto bg-transparent bg-light border border-light">
          Dettagli Proforma
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{backgroundColor: 'hsl(0, 0%, 95%)', maxHeight: 'none'}}>
        <Tabs defaultActiveKey="overview" id="info-modal-tabs" className="mb-3">
        <Tab eventKey="details" title={<span className="mx-3"><FaList className="me-1"/> Lista</span>}>
        <>
            <div className="w-50 mx-auto shadow p-2 bg-light"  style={{borderRadius: '25px'}}>
              <p>
                <strong>Id Cliente:</strong> {rowData.codice_cliente}
              </p>
              <p>
                <strong>Ragione Sociale:</strong> {rowData.ragione_sociale}
              </p>
              <p>
                <strong>Descrizione Pagamento:</strong>{" "}
                {rowData.descrizione_pagamento}
              </p>
              <p>
                <strong>Percentuale Dovuta:</strong>{" "}
                {rowData.percentuale_dovuta}%
              </p>
              <p>
                <strong>Fido Concesso:</strong>{" "}
                {formatToEuro(rowData["Fido Concesso"])}
              </p>
              <p>
                <strong>Deposito:</strong>{" "}
                {formatToEuro(rowData["Deposito"])}
              </p>
              <p>
                <strong>Esposizione:</strong>{" "}
                {formatToEuro(rowData["Esposizione"])}
              </p>
              <p>
                <strong>Contabile:</strong> {formatToEuro(rowData.Contabile)}
              </p>
              <p>
                <strong>Proforme:</strong> {formatToEuro(rowData.Proforme)}
              </p>
              <p>
                <strong>Back Order:</strong>{" "}
                {formatToEuro(rowData["Back Order"])}
              </p>
              <p>
                <strong>Liste Aperte:</strong>{" "}
                {formatToEuro(rowData["Liste Aperte"])}
              </p>
              <p>
                <strong>Liste Aperte ODP:</strong>{" "}
                {formatToEuro(rowData["Liste Aperte ODP"])}
              </p>
              <p>
                <strong>Deposito Richiesto (BO):</strong>{" "}
                {formatToEuro(rowData["Deposito Richiesto (BO)"])}
              </p>
              <p>
                <strong>Pag Richiesto (BO + Ft):</strong>{" "}
                {formatToEuro(rowData["Pag Richiesto (BO + Ft)"])}
              </p>
              <p>
                <strong>Pagamenti Proforme:</strong>{" "}
                {formatToEuro(rowData["Pagamenti Proforme"])}
              </p>
              </div>
            </>
          </Tab>

          <Tab eventKey="overview" title={<span className="mx-3"><FaTable className="me-1"/>{"  "} Tabella</span>} style={{fontSize: '0.75em'}}>
            <div>
              <Card className="w-50 mx-auto mb-3 border-light shadow"  style={{borderRadius: '25px'}}>
                <Card.Body className="fw-bold mt-2">
                  <p>
                  {rowData.codice_cliente} - {rowData.ragione_sociale}
                  </p>
                  <hr></hr>
                  <p>
                    {rowData.descrizione_pagamento}
                    </p>
                </Card.Body>
              </Card>
            </div>
            <div className="table-responsive">
              <table className="table bg-light shadow w-50 mx-auto"  style={{borderRadius: '25px'}}>
                <tbody>
                  <tr>
                    <td className="w-50" style={{borderRadius: '25px 0px 0px 0px'}}>DEPOSITO</td>
                    <td className="w-25">{rowData["Codice Conto DE"]}</td>
                    <td className="w-25" style={{borderRadius: '0px 25px 0px 0px'}}>{formatToEuro(rowData.Deposito) ?? "0 €"}</td>
                  </tr>
                  <tr>
                    <td>ACCONTO</td>
                    <td>{rowData["Codice Conto AC"]}</td>
                    <td>{formatToEuro(rowData.Acconti) ?? "0 €"}</td>
                  </tr>
                  <tr>
                    <td>ESPOSIZIONE</td>
                    <td>{rowData["Codice Conto"]}</td>
                    <td>{formatToEuro(rowData.Esposizione) ?? "0 €"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold  fs-6" style={{borderRadius: '0px 0px 0px 25px', backgroundColor: '#FAEBD7'}}>CONTABILE</td>
                    <td style={{backgroundColor: '#FAEBD7'}}></td>
                    <td className="fw-bold  fs-6" style={{borderRadius: '0px 0px 25px 0px', backgroundColor: '#FAEBD7'}}>{formatToEuro(rowData.Contabile) ?? "0 €"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table bg-light shadow w-50 mx-auto"  style={{borderRadius: '25px'}}>
                <tbody>
                  <tr className="rounded">
                    <td style={{borderRadius: '25px 0px 0px 0px'}}></td>
                    <td></td>
                    <td style={{borderRadius: '0px 25px 0px 0px'}}><span>Percentuale dovuta: {rowData.percentuale_dovuta}%</span></td>
                  </tr>
                  <tr className="rounded">
                    <td className="w-50">PROFORMA</td>
                    <td className="w-25">{formatToEuro(rowData.Proforme) ?? "0 €"}</td>
                    <td className="w-25">{formatToEuro(rowData["Pagamenti Proforme"])}</td>
                  </tr>
                  <tr>
                    <td>BACK ORDER</td>
                    <td>{formatToEuro(rowData["Back Order"]) ?? "0 €"}</td>
                    <td>{formatToEuro(rowData["Percentuale Back Order"])}</td>
                  </tr>
                  <tr>
                    <td>LISTE APERTE</td>
                    <td>{formatToEuro(rowData["Liste Aperte"]) ?? "0 €"}</td>
                    <td>{formatToEuro(rowData["Percentuale Liste Aperte"])}</td>
                  </tr>
                  <tr>
                    <td>LISTE APERTE ODP</td>
                    <td>{formatToEuro(rowData["Liste Aperte ODP"]) ?? "0 €"}</td>
                    <td>{formatToEuro(rowData["Percentuale Liste Aperte ODP"])}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold  fs-6"  style={{borderRadius: '0px 0px 0px 25px', backgroundColor: '#FAEBD7'}}>TOTALE DOVUTO</td>
                    <td style={{backgroundColor: '#FAEBD7'}}></td>
                    <td className="fw-bold  fs-6"  style={{borderRadius: '0px 0px 25px 0px', backgroundColor: '#FAEBD7'}}>{formatToEuro(rowData["%_totale_dovuto"])}</td>
                    </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table shadow w-50 mx-auto"  style={{borderRadius: '25px', backgroundColor: 'gray'}}>
                <tbody>
                  <tr>
                    <td className="w-50" style={{borderRadius: '25px 0px 0px 0px', backgroundColor: '#FAEBD7'}}>CONTABILE</td>
                    <td className="w-25" style={{backgroundColor: '#FAEBD7'}}></td>
                    <td className="w-25" style={{borderRadius: '0px 25px 0px 0px', backgroundColor: '#FAEBD7'}}>{formatToEuro(rowData.Contabile) ?? "0 €"}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor: '#FAEBD7'}}>TOTALE DOVUTO</td>
                    <td style={{backgroundColor: '#FAEBD7'}}></td>
                    <td style={{backgroundColor: '#FAEBD7'}}>{formatToEuro(rowData["%_totale_dovuto"])}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor: '#FAEBD7'}}>FIDO CONCESSO</td>
                    <td style={{backgroundColor: '#FAEBD7'}}></td>
                    <td style={{backgroundColor: '#FAEBD7'}}>{formatToEuro(rowData["Fido Concesso"])}</td>
                  </tr>
                  <tr>
                    <td style={{borderRadius: '0px 0px 0px 25px', backgroundColor: '#FAEBD7'}} className="fw-bold fs-6">TOTALE</td>
                    <td style={{backgroundColor: '#FAEBD7'}}></td>
                    <td style={{borderRadius: '0px 0px 25px 0px', backgroundColor: '#FAEBD7'}}><span className="text-modal fs-6" style={{backgroundColor: '#FAEBD7'}}>{formatToEuro(rowData["%_totale_totale"])}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <img
          src={logo}
          alt="Logo"
          className="mx-auto p-2"
          style={{ width: "200px" }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
