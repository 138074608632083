import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";
import logo from "../images/TP-_logo-orizz-claim-color.png";

function BasicExample() {
  const [sortedClientiData, setSortedClientiData] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "valore",
    direction: "descending",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("mancata_vendita_dashboard.json");

        if (res.data && res.data.TP && Array.isArray(res.data.TP.localita)) {
          const clientiData = res.data.TP.localita;

          const transformedData = clientiData.reduce((acc, item) => {
            const existingClient = acc.find(
              (client) => client.id_cliente === item.id_cliente
            );

            // Controlla se il valore è definito e non è null
            const valore = item.Valore
              ? parseFloat(item.Valore.replace(",", "."))
              : 0;

            // Controlla se il numero_doc è definito e non è null
            const numeroDoc = item.numero_doc
              ? parseInt(item.numero_doc, 10)
              : 0;

            if (existingClient) {
              existingClient.valore += valore;
              existingClient.numero += numeroDoc; // Somma numero_doc se il cliente già esiste
              existingClient.dettagli.push({
                brand: item.brand,
                precodice: item.precodice,
                valore: valore,
              });
            } else {
              acc.push({
                id_cliente: item.id_cliente,
                cliente: item.clienti,
                valore: valore,
                numero: numeroDoc, // Assegna numero_doc per il nuovo cliente
                localita: item.regione || item.nazione,
                origine: item.origine,
                dettagli: [
                  {
                    brand: item.brand,
                    precodice: item.precodice,
                    valore: valore,
                  },
                ],
              });
            }
            return acc;
          }, []);

          const sortedData = sortArray(
            transformedData,
            sortConfig.key,
            sortConfig.direction
          );
          setSortedClientiData(sortedData);
        } else {
          setError(
            "Dati delle località non disponibili o in formato non corretto."
          );
          setSortedClientiData([]);
        }
      } catch (err) {
        console.error("Errore durante il fetch dei dati:", err);
        setIsError(true);
        setError(err.message);
      }
    };

    fetchData();
  }, [sortConfig]);

  const sortArray = (array, key, direction) => {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = "clienti.xlsx";
    const data = sortedClientiData.map((item) => ({
      Cliente: item.cliente,
      Valore: formatToEuro(item.valore),
      Numero: item.numero,
      Località: item.localita,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, fileName);
  };

  const handleShowModal = (cliente) => {
    setSelectedCliente(cliente);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCliente(null);
  };

  return (
    <div
      className="bg-light widget-client2 ombra tb hover-effect"
      style={{ borderRadius: "25px" }}
    >
      <Table className="tb border-$gray-200" style={{ fontSize: "0.8rem" }}>
        <thead>
          <tr>
            <th className="bg-dark text-light sticky-top">Clienti</th>
            <th
              className="bg-dark text-light sticky-top"
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("localita")}
            >
              Località
            </th>
            <th
              className="bg-dark text-light sticky-top"
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("valore")}
            >
              Valore
            </th>
            <th className="bg-dark text-light sticky-top">Numero</th>
            <th className="bg-dark text-light sticky-top">Origine</th>
            <th
              className="bg-success text-light sticky-top"
              onClick={exportToExcel}
            >
              <SiMicrosoftexcel className="fs-5 excel" />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedClientiData.map((clientiItem, index) => (
            <tr key={index} onClick={() => handleShowModal(clientiItem)}>
              <td style={{ cursor: "pointer" }}>{clientiItem.cliente}</td>
              <td>{clientiItem.localita}</td>
              <td>{formatToEuro(clientiItem.valore)}</td>
              <td>{clientiItem.numero}</td>
              <td>{clientiItem.origine}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modale per mostrare i dettagli del cliente */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="rounded-0 modale-test w-100"
      >
        <Modal.Header>
          <Modal.Title className="text-modal mx-auto bg-transparent bg-light border border-light">
            {selectedCliente?.cliente}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCliente?.dettagli.map((dettaglio, dettaglioIndex) => (
            <p className="text-center fs-4" key={dettaglioIndex}>
              <span className="fw-bold">{dettaglio.brand}: </span>{" "}
              {formatToEuro(dettaglio.valore)}
            </p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <img
            src={logo}
            alt=""
            className="mx-auto p-2"
            style={{ width: "200px" }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BasicExample;
