import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/amotive-orange-revert.png";
import logoInvert from "../images/TP-_logo-symbol-white.png";
import logoInvert2 from "../images/sud-ricambi-logo-symbol-white.png";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function BasicExample() {
  return (
    <Navbar
      expand="lg"
      style={{
        background:
          "linear-gradient(90deg, rgba(204,76,17,1) 0%, rgba(241,111,36,1) 50%)",
        maxHeight: "50px",
      }}
      className="full-width-navbar shadow p-0 sticky-navbar fixed-bottom footer-mobile"
    >
      <Container fluid style={{height: '50px'}}>
        <Link to={"/technoparts"}>
          {" "}
          <Navbar.Brand href="#home" className="fw-bold mx-3">
            <img
              alt=""
              src={logoInvert}
              width=""
              height=""
              className="d-inline-block align-middle mb-1"
              style={{ maxWidth: "30px" }}
            />
            <IoIosArrowForward className="text-light fs-2" />
          </Navbar.Brand>{" "}
        </Link>
        <Link to={"/sud-ricambi"}>
          {" "}
          <Navbar.Brand href="#home" className="fw-bold">
            <img
              alt=""
              src={logoInvert2}
              width=""
              height=""
              className="d-inline-block align-middle mb-1 logo"
              style={{ maxWidth: "45px" }}
            />
            <IoIosArrowForward className="text-light fs-2" />
          </Navbar.Brand>{" "}
        </Link>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
