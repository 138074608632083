import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/amotive-orange-revert.png";
import logoInvert from "../images/TP-_logo-symbol-white.png";
import logoInvert2 from "../images/sud-ricambi-logo-symbol-white.png";
import { IoIosArrowForward } from "react-icons/io";
import { FaUnlock } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";

function BasicExample() {
  const [isLocked, setIsLocked] = useState(() => {
    const lockedState = localStorage.getItem("isLocked");
    return lockedState ? JSON.parse(lockedState) : true;
  });

  const handleLockClick = () => {
    setIsLocked(!isLocked);
  };

  
  useEffect(() => {
    localStorage.setItem("isLocked", JSON.stringify(isLocked));
  }, [isLocked]);

 useEffect(() => {
    let intervalId;

    if (!isLocked) {
      intervalId = setInterval(() => {
        window.location.reload();
      }, 150000);
    } else {
      intervalId = setInterval(() => {
        window.location.href = "/sud-ricambi";
      }, 150000);
    }

    return () => clearInterval(intervalId);
  }, [isLocked]);

  return (
    <Navbar
      expand="lg"
      style={{
        background:
          "linear-gradient(90deg, rgba(204,76,17,1) 0%, rgba(241,111,36,1) 50%)",
        maxHeight: "50px",
      }}
      className="full-width-navbar shadow p-0 sticky-navbar"
    >
      <Container fluid>
        <Navbar.Brand href="#home" className="fs-3 fw-bold mx-3">
          <img
            alt=""
            src={logo}
            width=""
            height=""
            className="d-inline-block align-middle mb-1"
            style={{ maxWidth: "100px" }}
          />
        </Navbar.Brand>

        <div className="d-flex align-items-center">
          <Link to={"/technoparts"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold mx-3 logo-mobile">
              <img
                alt=""
                src={logoInvert}
                width=""
                height=""
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>{" "}
          </Link>
          <Link to={"/sud-ricambi"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvert2}
                width=""
                height=""
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "45px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>{" "}
          </Link>
          <div class="toggle-button-cover-2 mt-2">
            <div id="button-3-2" class="button r">
              <input
                class="checkbox-2"
                type="checkbox"
                checked={isLocked}   uiul
                onChange={handleLockClick}
              />
              <div class="knobs-2"></div>
              <div class="layer-2"></div>
              <span className="lock-icon-2" isLocked={isLocked}>
                {isLocked ? (
                  <FaUnlock className="text-dark fs-6" />
                ) : (
                  <FaLock className="text-dark fs-6" />
                )}
              </span>
            </div>
          </div>
        </div>      </Container>
    </Navbar>
  );
}

export default BasicExample;



/*
        <ListGroup className=" mt-3 w-100 scrollable-listAR">
                    <ListGroup.Item
                      className="text-center fw-bold d-flex sticky-top bg-dark align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="mb-0 d-flex align-items-center text-modal2AR">
                        Precodice
                      </p>
                      <p
                        className="mb-0 d-flex align-items-center text-modal2AR"
                      >
                        Ricerche
                        <MdFilterAlt className="mx-1" />
                      </p>
                      <p
                        className="mb-0 d-flex align-items-center text-modal2AR"
                      >
                        Netto Totale
                        <MdFilterAlt className="mx-1" />
                      </p>
                    </ListGroup.Item>
                    {tpPrecodiceData.map((precodiceData, index) => (
                      <ListGroup.Item
                        key={index}
                        className="text-center fw-bold text-light bg-dark d-flex "
                      >
                        <p className="text-start">{precodiceData.precodice}</p>
                        <p className="mx-2">
                          {precodiceData.numero_ricerche_articolo}
                        </p>
                        <p>{formatToEuro(precodiceData.netto_totale)}</p>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
*/