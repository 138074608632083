import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import axios from "axios";
import { Table } from "react-bootstrap";
import Dettagli from "./DettagliOrdini";

// ... (existing imports)

function CombinedCardExample() {
  const [totalOrders, setTotalOrders] = useState(0);
  const [averageOrders, setAverageOrders] = useState(0);
  const [uniqueClientsCount, setUniqueClientsCount] = useState(0);
  const [origineData, setOrigineData] = useState("");
  const [origineData2, setOrigineData2] = useState("");
  const [valoreOrdini, setValoreOrdini] = useState("");
  const [valoreOrdini2, setValoreOrdini2] = useState("");
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [totalOrdered, setTotalOrdered] = useState(0);
  const [averageTotal, setAverageTotal] = useState(0);
  const [totalLastMinute, setTotalLastMinute] = useState(0);
  const handleCloseModal = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("./dati_dashboard.json");
        const {
          totale_ordinato,
          numero_ordini,
          origine,
          clienti,
          totale_lastminute,
        } = res.data.TP;

        if (totale_ordinato && parseFloat(totale_ordinato) > 0) {
          setTotalOrdered(parseFloat(totale_ordinato));
        }

        if (
          totale_lastminute !== undefined &&
          parseFloat(totale_lastminute) > 0
        ) {
          setTotalLastMinute(parseFloat(totale_lastminute));
        }

        if (numero_ordini) {
          setTotalOrders(numero_ordini);
        }

        if (origine && origine.length > 0) {
          let totalNumeroMedioWeb = 0;
          let totalNumeroMedioNoweb = 0;

          origine.forEach((item) => {
            if (item.origine === "web") {
              totalNumeroMedioWeb += parseFloat(item.numero_medio);
            } else if (item.origine === "noweb") {
              totalNumeroMedioNoweb += parseFloat(item.numero_medio);
            }
          });

          const totaleLastMinuteValue = parseFloat(totale_lastminute);

          if (!isNaN(totaleLastMinuteValue) && totaleLastMinuteValue >= 0) {
            setTotalLastMinute(totaleLastMinuteValue);
          } else {
            console.error(
              "Invalid or non-positive value for totale_lastminute:",
              totale_lastminute
            );
          }

          const totalNumeroMedio = totalNumeroMedioWeb + totalNumeroMedioNoweb;
          setAverageOrders(totalNumeroMedio); // Rimuovo la divisione per origine.length

          const origineWeb = origine.find((item) => item.origine === "web");
          const origineNoWeb = origine.find((item) => item.origine === "noweb");

          if (origineWeb && origineNoWeb) {
            setValoreOrdini(origineWeb.valore);
            setOrigineData(origineWeb.origine);
            setValoreOrdini2(origineNoWeb.valore);
            setOrigineData2(origineNoWeb.origine);
          }
        }

        if (clienti) {
          let uniqueClients = new Set();

          Object.values(clienti).forEach((clienteArray) => {
            clienteArray.forEach((clienteItem) => {
              uniqueClients.add(clienteItem.cliente);
            });
          });

          setUniqueClientsCount(uniqueClients.size);
        }
      } catch (err) {
        alert(err);
        setIsError(!isError);
        setError({ error });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAverageData = async () => {
      try {
        const res = await axios.get("./dati_dashboard.json");
        const { totale_ordinato, origine } = res.data.TP;

        if (totale_ordinato && parseFloat(totale_ordinato) > 0) {
          setTotalOrdered(parseFloat(totale_ordinato));
        }

        if (origine && origine.length > 0) {
          const webOrigin = origine.find((item) => item.origine === "web");
          const nowebOrigin = origine.find((item) => item.origine === "noweb");

          let webAverage = 0;
          let nowebAverage = 0;

          if (webOrigin) {
            webAverage = parseFloat(webOrigin.valore_medio);
          }

          if (nowebOrigin) {
            nowebAverage = parseFloat(nowebOrigin.valore_medio);
          }

          const totalAverage = webAverage + nowebAverage;
          setAverageTotal(totalAverage);
        }
      } catch (err) {
        alert(err);
        setIsError(!isError);
        setError({ error });
      }
    };

    fetchAverageData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  return (
    <>
      <Card
        className="border-transparent cs ombra hover-effect"
        style={{
          boxShadow: "2px 2px 10px 2px gray",
          border: "none",
          fontFamily: "Asap, sans-serif",
          borderRadius: "25px",
          cursor: "pointer",
        }}
        onClick={handleShowModal}
      >
        <Card.Body
          className="bg-dark d-flex flex-column align-items-center justify-content-center text-center p-2"
          style={{ borderRadius: "25px" }}
        >
          <p className="fw-bold mb-0 text-title">INFO ORDINI</p>

          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="text-light mb-0 fs-4">
              TOTALE:{" "}
              <span className="fw-bold text-light">
                {formatToEuro(totalOrdered)}
              </span>
            </p>
          </div>

          <div
            className="d-flex flex-wrap align-items-center justify-content-center mb-3"
            style={{ borderBottom: "0.5px solid white" }}
          >
            <p className="text-light mb-0 fs-5 mx-3">
              CLIENTI:{" "}
              <span className="fw-bold text-light">{uniqueClientsCount}</span>
            </p>
            <p className="text-light mb-0 fs-5 mx-3">
              ORDINI: <span className="fw-bold text-light">{totalOrders}</span>
            </p>
          </div>

          <Table size="sm" variant="dark">
            <tbody>
              <tr>
                <td className="text-start" colSpan={2}>
                  Totale media mensile: {formatToEuro(averageTotal)}
                </td>
                <td className="text-end">
                  {" "}
                  <p
                    className="fw-bold"
                    style={{ fontSize: "0.8em", margin: 0 }}
                  >
                    {totalOrdered !== 0 && (
                      <p
                        className="fw-bold"
                        style={{ fontSize: "0.9em", margin: 0 }}
                      >
                        {(
                          ((totalOrdered - averageTotal) / averageTotal) *
                          100
                        ).toFixed(2)}
                        %{" "}
                        {totalOrdered - averageTotal < 0 ? (
                          <FaArrowTrendDown style={{ color: "red" }} />
                        ) : (
                          <FaArrowTrendUp style={{ color: "green" }} />
                        )}
                      </p>
                    )}
                  </p>
                </td>
              </tr>
              <tr>
                <td className="text-start" colSpan={2}>
                  Totale media ordini: {averageOrders}
                </td>
                <td className="text-end">
                  {" "}
                  {averageOrders !== 0 && totalOrders !== 0 && (
                    <p style={{ fontSize: "0.7em", margin: 0 }}>
                      <div className="d-flex">
                        <p className="mb-0">
                          {(
                            ((totalOrders - averageOrders) / averageOrders) *
                            100
                          ).toFixed(2)}
                          %{" "}
                          {totalOrders - averageOrders < 0 ? (
                            <FaArrowTrendDown style={{ color: "red" }} />
                          ) : (
                            <FaArrowTrendUp style={{ color: "green" }} />
                          )}
                        </p>
                      </div>
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td className="w-50 text-start">
                  {origineData}: {formatToEuro(valoreOrdini)}
                </td>
                <td colSpan={2} className="text-end">
                  {origineData2}: {formatToEuro(valoreOrdini2)}
                </td>
              </tr>
              <tr>
                <td className="w-50 text-start">
                  Last Minute: {formatToEuro(totalLastMinute)}
                </td>
                <td colSpan={2} className="text-end">
                  Normale: {formatToEuro(totalOrdered - totalLastMinute)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Dettagli showModal={showModal} handleCloseModal={handleCloseModal} />
    </>
  );
}

export default CombinedCardExample;
