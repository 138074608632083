import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { Card } from "react-bootstrap";

function CityBarChart() {
  const [italyData, setItalyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("./dati_dashboard.json");
        const data = await response.json();
        const italyRegions = data.AR.paesi.ITALIA;

        // Sort by 'valore' in descending order
        italyRegions.sort((a, b) => parseFloat(b.valore) - parseFloat(a.valore));

        const top5Cities = italyRegions.slice(0, 5);

        const transformedData = top5Cities.map((region) => ({
          regione: region.regione,
          numero: parseFloat(region.numero),
          valore: parseFloat(region.valore),
        }));

        setItalyData(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const maxXValue =
    Math.max(...italyData.map((dataPoint) => dataPoint.numero)) + 1;

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };


  return (
    <>
      <Card className="rounded-0 ombra ordini-ora card-home border-light bg-dark hover-effect">
        <div className="mt-1 mx-auto">
          <p className="border border-danger text-title2 p-1 rounded">
            ORDINI RICEVUTI - ITALIA
          </p>
        </div>
        <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={italyData}
        layout="vertical"
        margin={{
          top: 0,
          right: 75,
          left: 75,
          bottom: 0,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="valore"
          domain={[0, maxXValue]}
          tickFormatter={(value) => formatToEuro(value)}
        />
        <YAxis
          dataKey="regione"
          type="category"
          hide={true}
        />
        <Bar
          dataKey="valore"
          fill="rgba(241, 111, 36, 1) "
          style={{ backdropFilter: "blur(10px)" }}
        >
          <LabelList
            dataKey="regione"
            position="insideLeft"
            fill="white"
            className="fw-bold"
            style={{ fontSize: "0.6em" }}
          />
          <LabelList
          dataKey="valore"
          formatter={(value) => formatToEuro(value)}
            
            position="left"
            fill="white"
            className="fw-bold"
            style={{ fontSize: "0.7em" }}
          />
          <LabelList
            dataKey="numero"
            position="right"
            fill="white"
            className="fw-bold"
            style={{ fontSize: "0.7em" }}
          />
        </Bar>
        <Tooltip
          formatter={(value) => formatToEuro(value)}
        />
      </BarChart>
    </ResponsiveContainer>
      </Card>
    </>
  );
}

export default CityBarChart;
