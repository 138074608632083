import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/sud-ricambi-logo-color@0.5x.png";
import logoInvertAr from "../images/amotive-logo-symbol-color.png";
import logoInvertTp from "../images/TP-_logo-symbol-color.png";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function BasicExample() {
  return (
    <Navbar
      expand="lg"
      className="full-width-navbar shadow p-0 sticky-navbar navbar-heigth footer-mobile fixed-bottom"
      style={{
        height: "80px",
        backgroundColor: "#b9b9b9a1",
        backdropFilter: "blur(10px)",
      }}
    >
      <Container fluid style={{ height: "50px" }}>
        <Link to={"/amotive"}>
          {" "}
          <Navbar.Brand href="#home" className="fw-bold mx-3">
            <img
              alt=""
              src={logoInvertAr}
              width=""
              height=""
              className="d-inline-block align-middle mb-1"
              style={{ maxWidth: "30px" }}
            />
            <IoIosArrowForward className="text-dark fs-2" />
          </Navbar.Brand>{" "}
        </Link>
        <Link to={"/technoparts"}>
          {" "}
          <Navbar.Brand href="#home" className="fw-bold">
            <img
              alt=""
              src={logoInvertTp}
              width=""
              height=""
              className="d-inline-block align-middle mb-1 logo"
              style={{ maxWidth: "30px" }}
            />
            <IoIosArrowForward className="text-dark fs-2" />
          </Navbar.Brand>{" "}
        </Link>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
