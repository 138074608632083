import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

const SalesChart = () => {
  const [hourlyData, setHourlyData] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("./dati_dashboard.json")
        .then((res) => {
          setHourlyData(res.data.TP.orario);
        })
        .catch((err) => {
          alert(err);
          setIsError(!isError);
          setError({ error });
        });
    };
    fetchData();
  }, []);

  const maxYValue =
    Math.max(...hourlyData.map((dataPoint) => dataPoint.valore)) + 1000;

  return (
    <Card className="ombra border-light card-home graphic-day hover-effect">
      <div className="mt-1 mx-auto">
        <h5 className="border border-danger text-title p-1 rounded">
          ORDINI PER OGNI ORA
        </h5>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={hourlyData}
          width={730}
          height={170}
          margin={{ top: 0, right: 75, left: 75, bottom: 0 }}
          className="gd"
        >
          <XAxis dataKey="orario" />
          <YAxis
            domain={[0, maxYValue]}
            tickFormatter={(value) =>
              `${parseFloat(value).toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            }
          />{" "}
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            formatter={(value, name) => [
              `${parseFloat(value).toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
              name,
            ]}
          />
          <Line
            type="monotone"
            dataKey="valore"
            stroke="#b81f25"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SalesChart;
