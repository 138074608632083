import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OpeningPage from "./components/OpeningPage";
import Technoparts from "./components/Technoparts";
import Amotive from "./components/AmotiveTP";
import SudRicambi from "./components/SudRicambi";
import Proforma from "./components/Proforma";

function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<OpeningPage />} />
        <Route path="/technoparts" element={<Technoparts />} />
        <Route path="/amotive" element={<Amotive />} />
        <Route path="/sud-ricambi" element={<SudRicambi />} />
        <Route path="/proforma" element={<Proforma />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
