import NavbarTP from "./technoparts/NavbarTopTP";
import CardSell3TP from "./technoparts/CardSell3TP";
import Dettagli from "./technoparts/DettagliManVen";
import CardSell2TP from "./technoparts/CardSell2TP";
import AreaChartTP from "./technoparts/AreaChartTP";
import GraphicDayTP from "./technoparts/GraphicDayTP";
import GraphicWeekTP from "./technoparts/GraphicWeekTP";
import TabsTP from "./technoparts/TabsClientTP";
import TabsTipoCliTpTP from "./technoparts/TabsTipoCliTp";
import BarChartTP from "./technoparts/BarChartTP";
import BarChartIt from "./technoparts/BarChartIt";
import FooterMobile from "./technoparts/FooterMobileTp";
import GoogleMap from "./technoparts/GoogleMap";
import React, { useState } from "react";
import "../App.css";

function Home() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const apikey = 'AIzaSyD16h98hJe26HkkEvzKPbH2WytgT5srggM';

  return (
    <div className="main-container">
      <NavbarTP />
      <div className="technoparts">
        <GraphicDayTP />
        <GraphicWeekTP />
        <GoogleMap apiKey={apikey} />
        <div className="card-container">
          <CardSell3TP />
          <CardSell2TP onCardClick={handleShowModal} />
          <Dettagli showModal={showModal} handleCloseModal={handleCloseModal}  />
        </div>
        <TabsTP />
        <TabsTipoCliTpTP />
        <AreaChartTP />
        <BarChartTP />
        <BarChartIt />
      </div>
      <FooterMobile />
    </div>
  );
}

export default Home;
