import React, { useState } from 'react'; // Import useState hook
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';

const ExportToExcelButton = ({ filename }) => {
  const [loading, setLoading] = useState(false); // Initialize loading state

  const handleExport = async () => {
    setLoading(true); // Set loading state to true when request starts

    try {
      // Fetch data from the API endpoint
      const res = await axios.get("ws/proforma.php");

      // Extract the 'TP' data from the response
      const { TP } = res.data;

      // Check if TP is valid and is an array
      if (!Array.isArray(TP) || TP.length === 0) {
        console.error("Dati non validi o vuoti:", TP);
        setLoading(false); // Set loading state to false on error
        return;
      }

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert the 'TP' data to a worksheet
      const ws = XLSX.utils.json_to_sheet(TP);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate Excel file and create a Blob
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });

      // Save the Excel file
      saveAs(blob, filename || 'data.xlsx');
    } catch (error) {
      console.error("Errore durante l'esportazione:", error);
    } finally {
      setLoading(false); // Always set loading state to false when request completes
    }
  };

  return (
    <button onClick={handleExport} className="btn btn-success mt-4" disabled={loading}>
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : (
        "Esporta in Excel"
      )}
    </button>
  );
};

export default ExportToExcelButton;
