import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../App.css";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import InfoModal from "./InfoModal"; // Assicurati di avere il percorso corretto
import { FaTriangleExclamation } from "react-icons/fa6";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap"; // Importa Spinner, OverlayTrigger e Tooltip di Bootstrap

const ProformaTable = ({ searchQuery }) => {
  const [proformaData, setProformaData] = useState([]);
  const [highlightedText, setHighlightedText] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(true); // Stato di caricamento
  const [error, setError] = useState(null); // Stato per gestire l'errore

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("ws/proforma.php");
        console.log("Risposta dell'API:", res.data);

        if (res.data && res.data.TP) {
          const proforma = res.data.TP;
          setProformaData(proforma);
        } else {
          setError("La risposta del server non contiene i dati attesi.");
        }
      } catch (error) {
        setError("Errore nel recupero dei dati.");
        console.error("Errore nel recupero dei dati:", error);
      } finally {
        setLoading(false); // Imposta loading su false al termine della chiamata API
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setHighlightedText(searchQuery);
      const timeoutId = setTimeout(() => {
        setHighlightedText(null);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [searchQuery]);

  const highlightText = (text) => {
    if (!text || !highlightedText) return text;

    const parts = text.split(new RegExp(`(${highlightedText})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlightedText.toLowerCase() ? (
        <span key={index} className="bg-warning">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const formatToEuro = (value) => {
    if (typeof value === "number" || !isNaN(parseFloat(value))) {
      return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    }
    return value;
  };

  const sortedData = React.useMemo(() => {
    const sortableData = [...proformaData];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        const aValue = parseFloat(a[sortConfig.key]) || 0;
        const bValue = parseFloat(b[sortConfig.key]) || 0;

        if (sortConfig.direction === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });
    }
    return sortableData;
  }, [proformaData, sortConfig]);

  const filteredData = sortedData.filter((item) =>
    Object.values(item).some((val) =>
      String(val).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getContabileClass = (value) => {
    const numValue = parseFloat(value);
    if (numValue > 0) {
      return "text-danger"; // rosso
    } else if (numValue < 0) {
      return "text-success"; // verde
    }
    return ""; // Nessuna classe
  };

  const handleRowClick = (index) => {
    setSelectedRow(index); // Aggiorna lo stato della riga selezionata
  };

  const handleModalOpen = (rowData) => {
    setModalData(rowData);
    setModalShow(true);
  };

  const columnTooltips = {
    "Id Cliente": "Identificativo univoco del cliente.",
    "Ragione Sociale": "Nome dell'azienda o ragione sociale del cliente.",
    Pagamento: "Metodo di pagamento utilizzato dal cliente.",
    "Perc Dovuta": "Percentuale dovuta.",
    Fido: "Importo del fido concesso al cliente.",
    "Contabile (CL + DE + AC)": "Saldo contabile attuale del cliente. Dare - Avere",
    Proforme: "Valore delle proforme emesse.",
    "Pagamenti Proforme": "Percentuale dovuta dei pagamenti effettuati per le proforme.",
  };

  return (
    <div className="mt-4">
      {loading ? (
        <div className="text-center spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Caricamento...</span>
          </Spinner>
        </div>
      ) : error ? (
        <div className="text-center">
          <FaTriangleExclamation className="text-secondary fs-4 mt-auto" />
          <p className="text-secondary mt-2">Dati non disponibili. {error}</p>
        </div>
      ) : (
        <div className="table-container table-scroll-container">
          <table className="table table-bordered table-prof">
            <thead>
              <tr>
                {Object.keys(columnTooltips).map((columnName, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        {columnTooltips[columnName]}
                      </Tooltip>
                    }
                  >
                    <th
                      style={{ fontSize: "0.7rem" }}
                      className={`bg-dark text-light sticky-top column-${index + 1}`}
                      onClick={() => handleSort(columnName)}
                    >
                      {columnName}
                      {sortConfig.key === columnName ? (
                        sortConfig.direction === "asc" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  </OverlayTrigger>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(index)}
                  className={selectedRow === index ? "row-selected" : ""}
                >
                  <td
                    className="fw-bold column-1"
                    style={{ fontSize: "0.8rem", cursor: "pointer", backgroundColor: '#ffbcbc' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModalOpen(item);
                    }}
                  >
                    {highlightText(item.codice_cliente)}
                  </td>
                  <td className="column-2" style={{ fontSize: "0.8rem" }}>
                    {highlightText(item.ragione_sociale)}
                  </td>
                  <td className="column-3" style={{ fontSize: "0.8rem" }}>
                    {highlightText(item.descrizione_pagamento)}
                  </td>
                  <td className="column-4 text-end" style={{ fontSize: "0.8rem" }}>
                    {highlightText(item.percentuale_dovuta + "%")}
                  </td>
                  <td className="column-5 text-end" style={{ fontSize: "0.8rem" }}>
                    {highlightText(formatToEuro(item["Fido Concesso"]))}
                  </td>
                  <td
                    className={`column-6 text-end ${getContabileClass(item.Contabile)}`}
                    style={{ fontSize: "0.8rem" }}
                  >
                    {highlightText(formatToEuro(item.Contabile))}
                  </td>
                  <td className="column-8 text-end" style={{ fontSize: "0.8rem" }}>
                    {highlightText(formatToEuro(item.Proforme))}
                  </td>
                  <td className="column-9 text-end" style={{ fontSize: "0.8rem" }}>
                    {highlightText(formatToEuro(item["Pagamenti Proforme"]))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <InfoModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        rowData={modalData} // Assicurati che `modalData` contenga i dati corretti
      />
    </div>
  );
};

export default ProformaTable;
