import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/sud-ricambi-logo-color@0.5x.png";
import logoInvertAr from "../images/amotive-logo-symbol-color.png";
import logoInvertTp from "../images/TP-_logo-symbol-color.png";
import { IoIosArrowForward } from "react-icons/io";
import { FaUnlock } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";

function BasicExample() {
  const [isLocked, setIsLocked] = useState(() => {
    const lockedState = localStorage.getItem("isLocked");
    return lockedState ? JSON.parse(lockedState) : true;
  });

  const handleLockClick = () => {
    setIsLocked(!isLocked);
  };

  useEffect(() => {
    localStorage.setItem("isLocked", JSON.stringify(isLocked));
  }, [isLocked]);

  useEffect(() => {
    let intervalId;

    if (!isLocked) {
      intervalId = setInterval(() => {
        window.location.reload();
      }, 150000);
    } else {
      intervalId = setInterval(() => {
        window.location.href = "/technoparts";
      }, 150000);
    }

    return () => clearInterval(intervalId);
  }, [isLocked]);

  return (
    <Navbar
      expand="lg"
      className="full-width-navbar shadow p-0 sticky-navbar"
      style={{
        maxHeight: "50px",
        backgroundColor: '#b9b9b9a1 ',
        backdropFilter: 'blur(10px)'
      }}
    >
      <Container fluid>
        <Navbar.Brand href="#home" className="fs-3 fw-bold mx-3">
          <img
            alt=""
            src={logo}
            width=""
            height=""
            className="d-inline-block align-middle"
            style={{ maxWidth: "120px" }}
          />
        </Navbar.Brand>

        <div className="d-flex align-items-center">
          <Link to={"/amotive"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold mx-3 logo-mobile">
              <img
                alt=""
                src={logoInvertAr}
                width=""
                height=""
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-dark fs-2" />
            </Navbar.Brand>{" "}
          </Link>
          <Link to={"/technoparts"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvertTp}
                width=""
                height=""
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-dark fs-2" />
            </Navbar.Brand>{" "}
          </Link>
          <div class="toggle-button-cover-3 mt-2">
            <div id="button-3-3" class="button r">
              <input
                class="checkbox-3"
                type="checkbox"
                checked={isLocked}   uiul
                onChange={handleLockClick}
              />
              <div class="knobs-3"></div>
              <div class="layer-3"></div>
              <span className="lock-icon" isLocked={isLocked}>
                {isLocked ? (
                  <FaUnlock className="text-dark fs-6" />
                ) : (
                  <FaLock className="text-dark fs-6" />
                )}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
