import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/TP-_logo-orizz-claim-color-white-text.png";
import logoInvert from "../images/sud-ricambi-logo-symbol-white.png";
import logoInvert2 from "../images/amotive-logo-symbol-inverted-cut.png";
import logoInvert4 from "../images/TP-_logo-symbol-white.png";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function BasicExample() {
 

  return (
    <Navbar
      expand="lg"
      className="shadow p-0 sticky-navbar"
      style={{
        background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(121,9,9,1) 100%)",
        maxHeight: "50px",
      }}
    >
      <Container fluid>
        <Navbar.Brand href="#home" className="fs-3 fw-bold mx-3">
          <img
            alt=""
            src={logo}
            className="d-inline-block align-middle"
            style={{ maxWidth: "150px" }}
          />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Link to={"/amotive"} className="d-flex align-items-center">
            <Navbar.Brand className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvert}
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "45px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>
          </Link>
          <Link to={"/amotive"} className="d-flex align-items-center">
            <Navbar.Brand className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvert2}
                className="d-inline-block align-middle mb-1 logo"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>
          </Link>
          <Link to={"/technoparts"} className="d-flex align-items-center">
            <Navbar.Brand className="fw-bold logo-mobile">
              <img
                alt=""
                src={logoInvert4}
                className="d-inline-block align-middle mb-1 logo"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>
          </Link>
        </div>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
