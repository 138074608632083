import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/TP-_logo-orizz-claim-color-white-text.png";
import logoInvert from "../images/sud-ricambi-logo-symbol-white.png";
import logoInvert2 from "../images/amotive-logo-symbol-inverted-cut.png";
import logoInvert3 from "../images/logo_proforma-removebg-preview (1).png";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function BasicExample() {

  return (
    <Navbar
      expand="lg"
      className="full-width-navbar shadow p-0 sticky-navbar navbar-heigth fixed-bottom footer-mobile"
      style={{
        background:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(121,9,9,1) 100%",
        maxHeight: "60px",
      }}
    >
      <Container fluid style={{height: '50px'}}>
          <Link to={"/sud-ricambi"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold mx-3">
              <img
                alt=""
                src={logoInvert}
                width=""
                height=""
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "45px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>{" "}
          </Link>

          <Link to={"/proforma"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold mx-3">
              <img
                alt=""
                src={logoInvert3}
                width=""
                height=""
                className="d-inline-block align-middle mb-1"
                style={{ maxWidth: "45px" }}
              />
            </Navbar.Brand>{" "}
          </Link>
          
          <Link to={"/amotive"}>
            {" "}
            <Navbar.Brand href="#home" className="fw-bold">
              <img
                alt=""
                src={logoInvert2}
                width=""
                height=""
                className="d-inline-block align-middle mb-1 logo"
                style={{ maxWidth: "30px" }}
              />
              <IoIosArrowForward className="text-light fs-2" />
            </Navbar.Brand>{" "}
          </Link>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
