import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import axios from "axios";

function WithHeaderStyledExample() {
  const [quantityOrdered2023, setQuantityOrdered2023] = useState(0);
  const [quantityOrdered2024, setQuantityOrdered2024] = useState(0);
  const [percentuale, setPercentuale] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getDateRange = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // aggiungi 1 perché i mesi partono da 0
    const currentYear = today.getFullYear();
    const lastYear = currentYear - 1;

    // Formatta il mese e il giorno corrente con zero a sinistra se necessario
    const formatDigit = (digit) => {
      return digit < 10 ? "0" + digit : digit;
    };

    const formattedCurrentMonth = formatDigit(currentMonth);
    const formattedToday = formatDigit(today.getDate());

    // Costruisci le stringhe per le date
    const currentDateRange = `01/${formattedCurrentMonth}/${currentYear} - ${formattedToday}/${formattedCurrentMonth}/${currentYear}`;
    const lastYearDateRange = `01/${formattedCurrentMonth}/${lastYear} - ${formattedToday}/${formattedCurrentMonth}/${lastYear}`;

    return {
      currentDateRange,
      lastYearDateRange,
    };
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("./dati_dashboard.json")
        .then((res) => {
          const { mese } = res.data.AR;

          const entry2023 = mese.find((entry) => entry.anno === "2023");
          const entry2024 = mese.find((entry) => entry.anno === "2024");

          if (entry2023) {
            setQuantityOrdered2023(entry2023.valore_ordinato);
          }

          if (entry2024) {
            setQuantityOrdered2024(entry2024.valore_ordinato);

            const percentualChange =
              ((entry2024.valore_ordinato - entry2023.valore_ordinato) /
                entry2023.valore_ordinato) *
              100;
            setPercentuale(percentualChange.toFixed(2));
          }

          setIsLoading(false);
        })
        .catch((err) => {
          alert(err);
          setIsError(!isError);
          setPercentuale({ percentuale });
        });
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const { currentDateRange, lastYearDateRange } = getDateRange(); // Ottieni le date utilizzando la funzione getDateRange


  return (
    <Card
      className="rounded border-transparent cs hover-effect"
      style={{
        boxShadow: "2px 2px 10px 2px gray",
        border: "none",
        fontFamily: "Asap, sans-serif",
      }}
    >
      <Card.Body className="bg-dark ombra d-flex flex-column align-items-center justify-content-center text-center">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <p style={{ fontSize: "0.7em", color: 'rgba(241,111,36,1)' }} className="fw-bold mb-1">
          confronto al periodo che va dal <br/> {currentDateRange} al {" "}
            {lastYearDateRange}
          </p>

          <h5 className="fw-bold mx-3" style={{ color: "rgba(241,111,36,1)", fontSize: '1.1em' }} >
            {formatToEuro(quantityOrdered2023)}{" "}
            <span className="fs-6">(2023)</span>
          </h5>

          <h5
            className="fw-bold mx-3 mt-2"
            style={{ color: "rgba(241,111,36,1)", fontSize: '1.1em' }}
          >
            {formatToEuro(quantityOrdered2024)}{" "}
            <span className="fs-6">(2024)</span>
          </h5>

          <p
            className="fw-bold"
            style={{
              fontSize: "0.8em",
              margin: 0,
              color: "rgba(241,111,36,1)",
            }}
          >
            Confronto mensile annuale: {percentuale}
            {percentuale !== "" && (
              <>
                {parseFloat(percentuale) < 0 ? (
                  <FaArrowTrendDown style={{ color: "red" }} />
                ) : (
                  <FaArrowTrendUp style={{ color: "green" }} />
                )}
              </>
            )}
          </p>
        </div>
      </Card.Body>
    </Card>
  );
}

export default WithHeaderStyledExample;
