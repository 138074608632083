import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import Table from "react-bootstrap/Table";
import axios from "axios";

function WithHeaderStyledExample() {
  const [isLoading, setIsLoading] = useState(true);
  const [percentualeValore, setPercentualeValore] = useState("");
  const [percentualeQta, setPercentualeQta] = useState("");
  const [percentualeNumCli, setPercentualeNumCli] = useState("");
  const [mancataVenditaMedia, setMancataVenditaMedia] = useState({});
  const [mancataVenditaAttuale, setMancataVenditaAttuale] = useState({});
  const [quantityOrdered2023, setQuantityOrdered2023] = useState(0);
  const [quantityOrdered2024, setQuantityOrdered2024] = useState(0);
  const [percentuale, setPercentuale] = useState("");
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);

  const COLORS = ["#b81f25", "#585858"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Definisci la funzione getDateRange prima di utilizzarla
  const getDateRange = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // aggiungi 1 perché i mesi partono da 0
    const currentYear = today.getFullYear();

    // Calcola l'anno scorso
    const lastYear = currentYear - 1;

    // Formatta il mese e il giorno corrente con zero a sinistra se necessario
    const formatDigit = (digit) => {
      return digit < 10 ? "0" + digit : digit;
    };

    const formattedCurrentMonth = formatDigit(currentMonth);
    const formattedToday = formatDigit(today.getDate());

    // Costruisci le stringhe per le date
    const currentDateRange = `01/${formattedCurrentMonth}/${currentYear} - ${formattedToday}/${formattedCurrentMonth}/${currentYear}`;
    const lastYearDateRange = `01/${formattedCurrentMonth}/${lastYear} - ${formattedToday}/${formattedCurrentMonth}/${lastYear}`;

    return {
      currentDateRange,
      lastYearDateRange,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("./dati_dashboard.json");
        const { mese } = res.data.SUD;

        const entry2023 = mese.find((entry) => entry.anno === "2023");
        const entry2024 = mese.find((entry) => entry.anno === "2024");

        if (entry2023) {
          setQuantityOrdered2023(entry2023.valore_ordinato);
        }

        if (entry2024) {
          setQuantityOrdered2024(entry2024.valore_ordinato);

          const percentualChange =
            ((entry2024.valore_ordinato - entry2023.valore_ordinato) /
              entry2023.valore_ordinato) *
            100;
          setPercentuale(percentualChange.toFixed(2));
        }

        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const { currentDateRange, lastYearDateRange } = getDateRange(); // Ottieni le date utilizzando la funzione getDateRange

  // Calcola la somma totale dei valori
  // Converti i valori delle quantità in numeri prima di calcolare la somma
  const totalOrders =
    parseFloat(quantityOrdered2023) + parseFloat(quantityOrdered2024);

  // Calcola le percentuali rispetto alla somma totale
  const percent2023 = (quantityOrdered2023 / totalOrders) * 100;
  const percent2024 = (quantityOrdered2024 / totalOrders) * 100;

  console.log(totalOrders);

  // Struttura dei dati per il grafico
  const data = [
    { name: "2023", value: percent2023 },
    { name: "2024", value: percent2024 },
  ];

  useEffect(() => {
    const fetchData = () => {
      axios
        .get("./dati_dashboard.json")
        .then((res) => {
          const { mancata_vendita } = res.data.SUD;

          if (mancata_vendita) {
            setMancataVenditaMedia(mancata_vendita.media);
            setMancataVenditaAttuale(mancata_vendita.attuale);
            // Invertito l'ordine dei valori
            const diff =
              mancataVenditaAttuale.valore - mancataVenditaMedia.valore;
            const percentage = (diff / mancataVenditaMedia.valore) * 100;
            setPercentualeValore(percentage.toFixed(2));

            const diff2 = mancataVenditaAttuale.qta - mancataVenditaMedia.qta;
            const percentageQta = (diff2 / mancataVenditaMedia.qta) * 100;
            setPercentualeQta(percentageQta.toFixed(2));

            const diff3 =
              mancataVenditaAttuale.num_cli - mancataVenditaMedia.num_cli;
            const percentageNum_cli =
              (diff3 / mancataVenditaMedia.num_cli) * 100;
            setPercentualeNumCli(percentageNum_cli.toFixed(2));
          }

          setIsLoading(false);
        })
        .catch((err) => {
          alert(err);
          setIsError(true);
        });
    };

    fetchData();
  }, [mancataVenditaAttuale, mancataVenditaMedia]);

  //1401 a 1705

  return (
    <Card
      className="border-transparent cs ombra hover-effect"
      style={{
        boxShadow: "2px 2px 10px 2px gray",
        border: "none", 
        borderRadius: '25px'
      }}
    >
      <Card.Body
        className="d-flex flex-column align-items-center justify-content-center text-center p-2" style={{borderRadius: '25px'}}
      >
        <p className="border border-danger text-title3 p-1">DETTAGLI VENDITE</p>
        <p style={{ fontSize: "0.6em" }} className="fw-bold mb-1">
          confronto al periodo che va dal {currentDateRange} al{" "}
          {lastYearDateRange}
        </p>{" "}
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h5 className="text-dark fw-bold mx-3" style={{ fontSize: "1.1em" }}>
            TOTALE 2023: {formatToEuro(quantityOrdered2023)}{" "}
          </h5>
          <h5 className="text-dark fw-bold mx-3" style={{ fontSize: "1.1em" }}>
            TOTALE 2024: {formatToEuro(quantityOrdered2024)}{" "}
          </h5>
          <p
            className="text-dark fw-bold mb-2 pb-1"
            style={{
              fontSize: "0.6em",
              margin: 0,
              borderBottom: "1px solid black",
              width: "100%",
            }}
          >
            Confronto mensile annuale: {percentuale}
            {percentuale !== "" && (
              <>
                {parseFloat(percentuale) < 0 ? (
                  <FaArrowTrendDown style={{ color: "red" }} />
                ) : (
                  <FaArrowTrendUp style={{ color: "green" }} />
                )}
              </>
            )}
          </p>
        </div>
        <div className="w-100">
          <Table
            variant="light"
            className="text-center p-2"
            style={{ width: "100%", fontWeight: "bold", fontSize: "12px" }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "start" }}>Mancata vendita</th>
                <th>Media mensile</th>
                <th style={{ textAlign: "end" }}>Trend</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "start" }}>
                  {`${formatToEuro(mancataVenditaAttuale.valore)}`}
                </td>
                <td classname="">
                  {" "}
                  {`${formatToEuro(mancataVenditaMedia.valore)}`}
                </td>
                <td style={{ textAlign: "end" }}>
                  {percentualeValore}%{" "}
                  {percentualeValore > 0 ? (
                    <FaArrowTrendUp style={{ color: "red" }} />
                  ) : (
                    <FaArrowTrendDown style={{ color: "green" }} />
                  )}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "start" }}>
                  {`Qta: ${mancataVenditaAttuale.qta}`}
                </td>
                <td classname="">{`Qta: ${mancataVenditaMedia.qta}`}</td>
                <td style={{ textAlign: "end" }}>
                  {percentualeQta}%{" "}
                  {percentualeQta > 0 ? (
                    <FaArrowTrendUp style={{ color: "red" }} />
                  ) : (
                    <FaArrowTrendDown style={{ color: "green" }} />
                  )}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "start" }}>
                  {`Cli: ${mancataVenditaAttuale.num_cli}`}
                </td>
                <td classname="">{`Cli: ${mancataVenditaMedia.num_cli}`}</td>
                <td style={{ textAlign: "end" }}>
                  {percentualeNumCli}%{" "}
                  {percentualeNumCli > 0 ? (
                    <FaArrowTrendUp style={{ color: "red" }} />
                  ) : (
                    <FaArrowTrendDown style={{ color: "green" }} />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

export default WithHeaderStyledExample;
