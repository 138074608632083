import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import logo from "../images/TP-_logo-orizz-claim-color.png";
import Table from "react-bootstrap/Table";
import { getWeek } from "date-fns";
import Accordion from "react-bootstrap/Accordion";


const Dettagli = ({ showModal, handleCloseModal }) => {
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null); // Stato per il mese selezionato
  const [showDetailsModal, setShowDetailsModal] = useState(false); // Stato per il secondo modal
  const currentWeek = getWeek(new Date()); // Settimana corrente
  const [sortConfig, setSortConfig] = useState({
    key: "ordinato",
    direction: "descending",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("./mancata_vendita_dashboard.json");
        console.log("Data received: ", res.data); // Aggiungi questo per vedere se i dati vengono caricati
        setData(res.data.TP.lastminuti || []);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const currentWeekData = data.filter(
    (order) => parseInt(order.numero_settimana) === currentWeek
  );

  const currentWeekTotals = {
    web: 0,
    noweb: 0,
  };

  // Initialize totals here
  const totals = {
    web: 0,
    noweb: 0,
  };
  let totalOrdinato = 0;

  if (Array.isArray(currentWeekData)) {
    currentWeekData.forEach((order) => {
      const ordinato = parseFloat(order.ordinato) || 0;

      if (order.origine === "web") {
        currentWeekTotals.web += ordinato;
        totals.web += ordinato; // Update totals
      } else {
        currentWeekTotals.noweb += ordinato;
        totals.noweb += ordinato; // Update totals
      }
    });
  }

  // Data for the chart Web/NoWeb
  const webNowebChartData = [
    { name: "Web", total: currentWeekTotals.web },
    { name: "NoWeb", total: currentWeekTotals.noweb },
  ];

  // Prepare data for the daily totals chart
  const dailyTotals = {};

  currentWeekData.forEach((order) => {
    const ordinato = parseFloat(order.ordinato) || 0;

    if (order.origine === "web") {
      totals.web += ordinato;
    } else {
      totals.noweb += ordinato;
    }

    totalOrdinato += ordinato; // Sum up the total ordinato for the week
  });

  currentWeekData.forEach((order) => {
    const ordinato = parseFloat(order.ordinato) || 0;
    const day = order.numero_giorno;

    if (!dailyTotals[day]) {
      dailyTotals[day] = 0;
    }
    dailyTotals[day] += ordinato;
    totalOrdinato += ordinato;
  });

  const dayNumberToName = (dayNumber) => {
    switch (parseInt(dayNumber)) {
      case 1:
        return "Lunedì";
      case 2:
        return "Martedì";
      case 3:
        return "Mercoledì";
      case 4:
        return "Giovedì";
      case 5:
        return "Venerdì";
      case 6:
        return "Sabato";
      case 7:
        return "Domenica"; // Added case for Sunday if needed
      default:
        return "";
    }
  };

  // Prepare daily chart data
  const dailyChartData = Object.entries(dailyTotals).map(([day, total]) => ({
    day: dayNumberToName(day),
    total,
  }));

  const monthsToName = (monthNumber) => {
    switch (parseInt(monthNumber)) {
      case 1:
        return "Gennaio";
      case 2:
        return "Febbraio";
      case 3:
        return "Marzo";
      case 4:
        return "Aprile";
      case 5:
        return "Maggio";
      case 6:
        return "Giugno";
      case 7:
        return "Luglio";
      case 8:
        return "Agosto";
      case 9:
        return "Settembre";
      case 10:
        return "Ottobre";
      case 11:
        return "Novembre";
      case 12:
        return "Dicembre";
      default:
        return "";
    }
  };

  // Inizializzare un oggetto con tutti i mesi da gennaio a dicembre
  const initialMonthlyTotals = {
    Gennaio: 0,
    Febbraio: 0,
    Marzo: 0,
    Aprile: 0,
    Maggio: 0,
    Giugno: 0,
    Luglio: 0,
    Agosto: 0,
    Settembre: 0,
    Ottobre: 0,
    Novembre: 0,
    Dicembre: 0,
  };

  const formatToEuro = (amount) => {
    if (isNaN(amount)) {
      return "0 €";
    }
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const weeklyTotals = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
  };

  if (Array.isArray(currentWeekData)) {
    currentWeekData.forEach((order) => {
      const dayNumber = parseInt(order.number_day);
      const ordinato = parseFloat(order.ordinato) || 0;
      if (weeklyTotals[dayNumber] !== undefined) {
        weeklyTotals[dayNumber] += ordinato;
      }
    });
  }

  const tableData = Array.isArray(currentWeekData)
    ? currentWeekData.map((order) => ({
        ragioneSociale: order.ragione_sociale,
        numeroOrdini: order.numero_di_ordini,
        descNazione: order.desc_nazione,
        ordinato: formatToEuro(parseFloat(order.ordinato) || 0),
        dataAcquisto: new Date(order.data_acquisto).toLocaleDateString("it-IT"),
        dayName: dayNumberToName(order.number_day),
      }))
    : [];

  // Selezionare il mese e filtrare i dati per quel mese
  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    setShowDetailsModal(true); // Mostra il secondo modale
  };

  const filteredDataByMonth = data.filter(
    (order) => monthsToName(order.mese_ordine) === selectedMonth
  );

  const weekDataForSelectedMonth = filteredDataByMonth.reduce((acc, order) => {
    const week = `Settimana ${order.numero_settimana}`;
    const ordinato = parseFloat(order.ordinato) || 0;
    if (!acc[week]) {
      acc[week] = {
        1: 0, // Lunedì
        2: 0, // Martedì
        3: 0, // Mercoledì
        4: 0, // Giovedì
        5: 0, // Venerdì
        6: 0, // Sabato
        7: 0, // Domenica
      };
    }
    const dayNumber = parseInt(order.numero_giorno);
    if (acc[week][dayNumber] !== undefined) {
      acc[week][dayNumber] += ordinato;
    }
    return acc;
  }, {});

  // Sommare i valori "ordinato" in base a "number_day"

  if (Array.isArray(data)) {
    data.forEach((order) => {
      const ordinato = parseFloat(order.ordinato) || 0;
      const day = order.number_day;

      // Somma gli ordinati per ciascun giorno
      if (!dailyTotals[day]) {
        dailyTotals[day] = 0;
      }
      dailyTotals[day] += ordinato;

      // Somma per il totale complessivo
      totalOrdinato += ordinato;
    });
  }

  // Aggregare l'ordinato per mese
  const monthlyTotals = data.reduce((acc, order) => {
    const month = monthsToName(order.mese_ordine);
    const ordinato = parseFloat(order.ordinato) || 0;
    if (month) {
      acc[month] += ordinato;
    }
    return acc;
  }, { ...initialMonthlyTotals });

  // Trasformare i dati in un array adatto per il grafico a barre (per mesi)
  const monthChartData = Object.keys(monthlyTotals).map((month) => ({
    month,
    total: monthlyTotals[month],
  }));

  const sortArray = (array, key, direction) => {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = sortArray([...currentWeekData], sortConfig.key, sortConfig.direction);

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="rounded-0 modale-test w-100"
      >
        <Modal.Header className="bg-white">
          <Modal.Title className="text-modal mx-auto bg-transparent bg-light border border-light">
            Dettagli Last Minute - Week {currentWeek}
            <br />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap bg-white">
            <Card className="flex-card card-modale bg-light text-dark">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={webNowebChartData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 50,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    tickFormatter={(value) =>
                      `${parseFloat(value).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    }
                  />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip
                    formatter={(value, name) => [
                      `${parseFloat(value).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`,
                      name,
                    ]}
                  />
                  <Bar dataKey="total" fill="#b81f25" />
                </BarChart>
              </ResponsiveContainer>
            </Card>

            <Card className="flex-card card-modale bg-light text-dark">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={200}
                  data={dailyChartData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 50,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis
                    tickFormatter={(value) =>
                      `${parseFloat(value).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    }
                  />
                  <Tooltip
                    formatter={(value, name) => [
                      `${parseFloat(value).toLocaleString("it-IT", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`,
                      name,
                    ]}
                  />
                  <Bar dataKey="total" fill="grey" />
                </BarChart>
              </ResponsiveContainer>
            </Card>

            <Card className="flex-card card-modale bg-light text-dark scrollable-list">
              <Card.Body className="d-flex align-items-center flex-column">
                <hr />
                <h2 className="text-center fw-bold">
                  TOTALE ORDINATO LAST MINUTE
                </h2>
                <h1 className="text-center mt-3 bg-secondary text-light rounded p-3">
                  {formatToEuro(
                    currentWeekTotals.web + currentWeekTotals.noweb
                  )}
                </h1>
                <hr />
              </Card.Body>
            </Card>

            <Card className="flex-card card-modale bg-light text-dark scrollable-list" style={{ maxHeight: "250px" }}>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr style={{ position: "sticky", top: "0" }}>
                      <th style={{ backgroundColor: "#b81f25", color: "white" }}>
                        Codice Cliente
                      </th>
                      <th style={{ backgroundColor: "#b81f25", color: "white" }}>
                        Ragione Sociale
                      </th>
                      <th
                        style={{ backgroundColor: "#b81f25", color: "white", cursor: "pointer" }}
                        onClick={() => handleSort("data_ordine")}
                      >
                        Data Ordine {sortConfig.key === "data_ordine" && (sortConfig.direction === "ascending" ? '▲' : '▼')}
                      </th>
                      <th
                        style={{ backgroundColor: "#b81f25", color: "white", cursor: "pointer" }}
                        onClick={() => handleSort("ordinato")}
                      >
                        Ordinato {sortConfig.key === "ordinato" && (sortConfig.direction === "ascending" ? '▲' : '▼')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((order) => (
                      <tr key={order.codice_cliente}>
                        <td>{order.codice_cliente}</td>
                        <td>{order.ragione_sociale}</td>
                        <td>{new Date(order.data_ordine).toLocaleDateString("it-IT")}</td>
                        <td>{formatToEuro(parseFloat(order.ordinato) || 0)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card className="flex-card card-modale bg-light text-dark w-100" style={{ maxHeight: "250px"}}>
              <Card.Body>
                <h5 className="text-center">Totale Ordinato per Mese</h5>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={monthChartData}
                    margin={{ top: 0, right: 30, left: 70, bottom: 100 }}
                    onClick={(e) => handleMonthClick(e.activeLabel)}  // Gestisci il click su un mese
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={(value) => `${formatToEuro(value)}`} />
                    <Tooltip formatter={(value, name) => [`${formatToEuro(value)}`, name]} />
                    <Bar dataKey="total" fill="#b81f25">
                      {monthChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.month === selectedMonth ? "gray" : "#b81f25"} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <img
            src={logo}
            alt=""
            className="mx-auto p-2"
            style={{ width: "200px" }}
          />
        </Modal.Footer>
      </Modal>

      <Modal
  show={showDetailsModal}
  onHide={() => setShowDetailsModal(false)}
  centered
  size="md"
  className="p-5"
>
  <Modal.Header closeButton className="text-modal">
    <Modal.Title>Dettagli per {selectedMonth}</Modal.Title>
  </Modal.Header>
  <Modal.Body
    style={{ maxHeight: "400px", overflowY: "auto" }}
    className="bg-secondary text-light border border-dark"
  >
    {/* Use Accordion for displaying week and day data */}
    <Accordion defaultActiveKey="0">
      {Object.keys(weekDataForSelectedMonth).map((week, index) => {
        // Calculate the total for the week
        const weekTotal = Object.values(weekDataForSelectedMonth[week]).reduce(
          (acc, total) => acc + total,
          0
        );

        return (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>
              <span className="fw-bold text-modal me-1 bg-transparent">{week} -</span>{" "} {formatToEuro(weekTotal)} {/* Display the total next to the week's name */}
            </Accordion.Header>
            <Accordion.Body>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {Object.entries(weekDataForSelectedMonth[week]).map(
                  ([dayNumber, total], idx) => (
                    <li key={idx} className="m-2">
                      <span className="fw-bold">{dayNumberToName(dayNumber)}: </span>
                      {formatToEuro(total)}
                      <hr />
                    </li>
                  )
                )}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  </Modal.Body>
</Modal>


    </>
  );
};

export default Dettagli;
