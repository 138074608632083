import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoImage from './images/Dashboard__2_-removebg-preview.png';
import 'animate.css'; 

function OpeningPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/technoparts');
    }, 4000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 'auto'
      }}
    >
      <img
        src={LogoImage}
        alt=""
        style={{
          width: '100%', 
          maxWidth: '400px', 
        }}
        className="logo-image animate__animated animate__bounce animate__delay-2s"
      />
    </div>
  );
}

export default OpeningPage;
