import { MdFilterAlt } from "react-icons/md";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import React, { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ListGroup } from "react-bootstrap";
import logo from "../images/amotive-orange-revert-white-orange-symbol.png";

const Dettagli = ({ showModal, handleCloseModal }) => {
  const [tpData, setTpData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [tpPieData, setTpPieData] = useState([]);
  const [tpTipoClienteData, setTpTipoClienteData] = useState([]);
  const [tpClienteData, setTpClienteData] = useState([]);
  const [tpArticoloData, setTpArticoloData] = useState([]);
  const [tpPrecodiceData, setTpPrecodiceData] = useState([]);
  const [tpFamigliaData, setTpFamigliaData] = useState([]);
  const [visiteOnline, setVisiteOnline] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const articleRefs = useRef({});
  const COLORS = [
    "#dc4712",
    "#ee712b",
    "#cd4545",
    "#216583",
    "#d4d4d4",
    "#f0f0f0",
  ];
  const RADIAN = Math.PI / 180;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("./mancata_vendita_dashboard.json");
        const tpJsonData = res.data.AR.data;
        const tpJsonData2 = res.data.AR.visite;
        const tpJsonData3 = Array.isArray(res.data.TP.ordini) ? res.data.TP.ordini : [];

        // Grouping data for TP pie chart
        const tpTipoCliGroups = tpJsonData.tipo_cliente.reduce((acc, curr) => {
          const tipoCli = curr.tipo_cli;
          const ricerche = parseInt(curr.numero_ricerche_articolo, 10);
          if (!acc[tipoCli]) {
            acc[tipoCli] = 0;
          }
          acc[tipoCli] += ricerche;
          return acc;
        }, {});

        const tpPieChartData = Object.entries(tpTipoCliGroups).map(
          ([tipoCli, count]) => ({
            name: tipoCli,
            count: count,
          })
        );

        // Aggregating data for Precodice
        const tpPrecodiceGroups = tpJsonData.articolo.reduce((acc, curr) => {
          const precodice = curr.precodice;
          const ricerche = parseInt(curr.numero_ricerche_articolo, 10);
          const nettoTotale = curr.netto_totale
            ? parseFloat(curr.netto_totale.replace(/[^0-9,.-]+/g, "").replace(",", "."))
            : 0;
        
          if (!acc[precodice]) {
            acc[precodice] = { numero_ricerche_articolo: 0, netto_totale: 0 };
          }
          acc[precodice].numero_ricerche_articolo += ricerche;
          acc[precodice].netto_totale += nettoTotale;
          return acc;
        }, {});
        

        const tpOrdiniGroups = tpJsonData3.reduce((acc, curr) => {
          const precodice = curr.precodice;
          const totalQuantity = curr.total_quantity
            ? parseFloat(curr.total_quantity.replace(",", "."))
            : 0;
          const totalValue = curr.total_value
            ? parseFloat(curr.total_value.replace(",", "."))
            : 0;
        
          if (!acc[precodice]) {
            acc[precodice] = { total_quantity: 0, total_value: 0 };
          }
        
          acc[precodice].total_quantity += totalQuantity;
          acc[precodice].total_value += totalValue;
          return acc;
        }, {});
        

        const tpPrecodiceChartData = Object.entries(tpPrecodiceGroups).map(
          ([precodice, data]) => {
            const ordiniData = tpOrdiniGroups[precodice] || {
              total_quantity: 0,
              total_value: 0,
            };
            return {
              precodice,
              numero_ricerche_articolo: data.numero_ricerche_articolo,
              netto_totale: data.netto_totale,
              total_quantity: ordiniData.total_quantity,
              total_value: ordiniData.total_value,
            };
          }
        );

        const tpFamigliaGroups = tpJsonData.articolo.reduce((acc, curr) => {
          const famiglia = curr.famiglia;
          const ricerche = parseInt(curr.numero_ricerche_articolo, 10);
          const nettoTotale = parseFloat(
            curr.netto_totale.replace(/[^0-9,.-]+/g, "").replace(",", ".")
          );

          if (!acc[famiglia]) {
            acc[famiglia] = { numero_ricerche_articolo: 0, netto_totale: 0 };
          }
          acc[famiglia].numero_ricerche_articolo += ricerche;
          acc[famiglia].netto_totale += nettoTotale;
          return acc;
        }, {});

        const tpFamigliaChartData = Object.entries(tpFamigliaGroups).map(
          ([famiglia, data]) => ({
            famiglia,
            numero_ricerche_articolo: data.numero_ricerche_articolo,
            netto_totale: data.netto_totale,
          })
        );

        setTpPieData(tpPieChartData);
        setTpTipoClienteData(tpJsonData.tipo_cliente);
        setTpClienteData(tpJsonData.cliente);
        setTpArticoloData(tpJsonData.articolo);
        setTpPrecodiceData(tpPrecodiceChartData); // Update state with aggregated data for precodice
        setTpFamigliaData(tpFamigliaChartData); // Update state with aggregated data for precodice
        setVisiteOnline(tpJsonData2.visite_online);
      } catch (error) {
        console.error("Errore nel recupero dei dati JSON: ", error);
        setIsError(true);
      }
    };

    fetchData();
  }, []);
  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const parseEuroToNumber = (amount) => {
    if (typeof amount === "string") {
      return parseFloat(amount.replace(/[^0-9,.-]+/g, "").replace(",", "."));
    } else if (typeof amount === "number") {
      return amount; // If it's already a number, return it directly
    } else {
      return 0; // If the amount is neither a string nor a number, return 0 or handle as needed
    }
  };

  const handleSort = (key, data, setData) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }

    const sortedData = [...data].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      if (key === "netto_totale") {
        aValue = parseEuroToNumber(aValue);
        bValue = parseEuroToNumber(bValue);
      } else if (key === "numero_ricerche_articolo") {
        aValue = parseInt(aValue, 10);
        bValue = parseInt(bValue, 10);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const handleArticleClick = (articolo) => {
    if (articleRefs.current[articolo.articolo]) {
      articleRefs.current[articolo.articolo].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const renderSortableHeader2 = (label, key, data, setData) => (
    <th
      className="bg-black border border-dark sticky-top"
      onClick={() => handleSort(key, data, setData)}
      style={{ cursor: "pointer", color: "white" }}
    >
      {label}
    </th>
  );

  const renderSortableHeader = (label, key, data, setData) => (
    <th
      className="bg-black border border-dark sticky-top"
      onClick={() => handleSort(key, data, setData)}
      style={{ cursor: "pointer", color: "rgba(241,111,36,1)" }}
    >
      {label}
    </th>
  );

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="rounded-0 modale-test w-100"
      >
        <Modal.Header className="bg-dark rounded-0">
          <Modal.Title className="text-modal2AR mx-auto bg-transparent border border-light">
            Dettagli Mancata Vendita
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          <div className="d-flex flex-wrap">
            <Card className="flex-card bg-black rounded-0 card-modale bg-dark text-dark">
              <Card.Body>
              <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={tpPieData}
                      dataKey="count"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      labelLine={false}
                      label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        percent,
                        name,
                      }) => {
                        const RADIAN = Math.PI / 180;
                        const radius =
                          innerRadius + (outerRadius - innerRadius) * 0.5;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        // Label customization
                        const outerRadiusLabel = outerRadius + 20;
                        const outerX =
                          cx + outerRadiusLabel * Math.cos(-midAngle * RADIAN);
                        const outerY =
                          cy + outerRadiusLabel * Math.sin(-midAngle * RADIAN);
                        const isLeftSide = outerX < cx;
                        const maxTextWidth = 80;

                        return (
                          <>
                            <text
                              x={x}
                              y={y}
                              fill="black"
                              textAnchor="middle"
                              dominantBaseline="central"
                              style={{ pointerEvents: "none" }}
                            >
                              {`${(percent * 100).toFixed(0)}%`}
                            </text>
                            <foreignObject
                              x={isLeftSide ? outerX - maxTextWidth : outerX}
                              y={outerY - 10}
                              width={maxTextWidth}
                              height={40}
                            >
                              <div
                                style={{
                                  textAlign: isLeftSide ? "right" : "left",
                                  whiteSpace: "normal",
                                  color: "white",
                                  fontSize: "12px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {name}
                              </div>
                            </foreignObject>
                          </>
                        );
                      }}
                    >
                      {tpPieData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Card.Body>
            </Card>

            <Card className="flex-card card-modale bg-black rounded-0 text-light d-flex p-3">
              <Card.Title className="text-center  p-4 fs-3 fw-bold mb-0">
                NUMERO VISITE
              </Card.Title>
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Subtitle className="mb-2 " style={{ fontSize: "5em" }}>
                  {visiteOnline}
                </Card.Subtitle>
                <Card.Text className="mt-5">
                  Il dato rappresenta il numero di utenti che hanno consultato
                  il sito ma non hanno completato l'acquisto.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="flex-card bg-black rounded-0 card-modale bg-dark text-light scrollable-listAR">
              <Card.Body>
                <div></div>
                <Tabs
                  defaultActiveKey="cliente"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="cliente" title="Cliente">
                    <Table>
                      <thead>
                        <tr>
                          <th className="bg-black text-light border border-dark sticky-top">
                            Regione Sociale
                          </th>
                          {renderSortableHeader(
                            "Ricerche",
                            "numero_ricerche_articolo",
                            tpClienteData,
                            setTpClienteData
                          )}
                          {renderSortableHeader(
                            "Netto Totale",
                            "netto_totale",
                            tpClienteData,
                            setTpClienteData
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {tpClienteData.map((cliente, index) => (
                          <tr
                            key={index}
                            onClick={() => handleArticleClick(cliente)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="bg-black text-light border border-dark">
                              {cliente.ragione_sociale}
                            </td>
                            <td
                              className="bg-black border border-dark"
                              style={{ color: "rgba(241,111,36,1)" }}
                            >
                              {cliente.numero_ricerche_articolo}
                            </td>
                            <td
                              className="bg-black border border-dark"
                              style={{ color: "rgba(241,111,36,1)" }}
                            >
                              {formatToEuro(cliente.netto_totale)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="tipoCliente" title="Tipo Cliente">
                    <Table>
                      <thead>
                        <tr>
                          <th className="bg-black text-light border border-dark sticky-top">
                            Tipo Cliente
                          </th>
                          {renderSortableHeader(
                            "Ricerche",
                            "numero_ricerche_articolo",
                            tpTipoClienteData,
                            setTpTipoClienteData
                          )}
                          {renderSortableHeader(
                            "Netto Totale",
                            "netto_totale",
                            tpTipoClienteData,
                            setTpTipoClienteData
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {tpTipoClienteData.map((tipo_cliente, index) => (
                          <tr
                            key={index}
                            onClick={() => handleArticleClick(tipo_cliente)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="bg-black text-light border border-dark">
                              {tipo_cliente.tipo_cli}
                            </td>
                            <td
                              className="bg-black border border-dark"
                              style={{ color: "rgba(241,111,36,1)" }}
                            >
                              {tipo_cliente.numero_ricerche_articolo}
                            </td>
                            <td
                              className="bg-black border border-dark"
                              style={{ color: "rgba(241,111,36,1)" }}
                            >
                              {formatToEuro(tipo_cliente.netto_totale)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>

            <Card className="flex-card bg-black card-modale bg-dark rounded-0 text-light scrollable-listAR">
              <Card.Body>
                <Tabs
                  defaultActiveKey="articolo"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="articolo" title="Articolo">
                    <Table className="pb-2">
                      <thead>
                        <tr>
                          <th className="bg-black text-light border border-dark sticky-top">
                            Articoli
                          </th>
                          {renderSortableHeader2(
                            "Precodice",
                            "precodice",
                            tpArticoloData,
                            setTpArticoloData
                          )}
                          <th className="bg-black text-light border border-dark sticky-top">
                            Categoria Sconto
                          </th>
                          <th className="bg-black text-light border border-dark sticky-top">
                            Famiglia
                          </th>
                          <th className="bg-black text-light border border-dark sticky-top">
                            Modello
                          </th>
                          {renderSortableHeader(
                            "Ricerche",
                            "numero_ricerche_articolo",
                            tpArticoloData,
                            setTpArticoloData
                          )}
                          {renderSortableHeader(
                            "Netto Totale",
                            "netto_totale",
                            tpArticoloData,
                            setTpArticoloData
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {tpArticoloData.map((articolo, index) => (
                          <tr
                            key={index}
                            onClick={() => handleArticleClick(articolo)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="bg-black text-light border border-dark">
                              {articolo.articolo}
                            </td>
                            <td className="bg-black text-light border border-dark">
                              {articolo.precodice}
                            </td>
                            <td className="bg-black text-light border border-dark">
                              {" "}
                              {articolo.categoria_sconto}
                            </td>
                            <td className="bg-black text-light border border-dark">
                              {articolo.famiglia}
                            </td>
                            <td className="bg-black text-light border border-dark">
                              {articolo.modello}
                            </td>
                            <td
                              className="bg-black border border-dark"
                              style={{ color: "rgba(241,111,36,1)" }}
                            >
                              {articolo.numero_ricerche_articolo}
                            </td>
                            <td
                              className="bg-black border border-dark"
                              style={{ color: "rgba(241,111,36,1)" }}
                            >
                              {formatToEuro(articolo.netto_totale)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="precodice" title="Precodice">
                    <Table>
                      <thead>
                        <tr>
                          <th
                            className="bg-black  border border-dark"
                            style={{ color: "rgba(241,111,36,1)" }}
                          >
                            Precodici
                          </th>
                          <th className="bg-black text-light  border border-dark">
                            Ricerche MV
                          </th>

                          <th className="bg-black text-light  border border-dark">
                            Netto MV
                          </th>
                          <th className="bg-black text-light  border border-dark">
                            Netto Ordini
                          </th>
                          <th className="bg-black text-light  border border-dark">
                            Ricerca Ordini
                          </th>
                          <th
                            className="bg-black  border border-dark"
                            style={{ color: "rgba(241,111,36,1)" }}
                          >
                            Ricerca Totale
                          </th>
                          <th
                            className="bg-black  border border-dark"
                            style={{ color: "rgba(241,111,36,1)" }}
                          >
                            Netto Totale
                          </th>
                          <th
                            className="bg-black  border border-dark"
                            style={{ color: "rgba(241,111,36,1)" }}
                          >
                            Disponibilità Ricercato
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tpPrecodiceData.map((precodice, index) => {
                          const ricerche = precodice.numero_ricerche_articolo;
                          const ordini = precodice.total_quantity;

                          return (
                            <tr key={index}>
                              <td
                                className="bg-black text-light border border-dark"
                                style={{ color: "rgba(241,111,36,1)" }}
                              >
                                {precodice.precodice}
                              </td>
                              <td className="bg-black text-light border border-dark">
                                {precodice.numero_ricerche_articolo}
                              </td>
                              <td className="bg-black text-light border border-dark">
                                {precodice.total_quantity}
                              </td>
                              <td className="bg-black text-light border border-dark">
                                {formatToEuro(precodice.netto_totale)}
                              </td>
                              <td className="bg-black text-light border border-dark">
                                {formatToEuro(precodice.total_value)}
                              </td>
                              <td
                                className="bg-black border border-dark"
                                style={{ color: "rgba(241,111,36,1)" }}
                              >
                                {precodice.total_quantity +
                                  precodice.numero_ricerche_articolo}
                              </td>
                              <td
                                className="bg-black border border-dark"
                                style={{ color: "rgba(241,111,36,1)" }}
                              >
                                {formatToEuro(
                                  precodice.total_value + precodice.netto_totale
                                )}
                              </td>
                              <td
                                className="bg-black border border-dark"
                                style={{ color: "rgba(241,111,36,1)" }}
                              >
                                {(
                                  (1 -
                                    (precodice.total_quantity +
                                      precodice.numero_ricerche_articolo -
                                      precodice.total_quantity) /
                                      (precodice.total_quantity +
                                        precodice.numero_ricerche_articolo)) *
                                  100
                                ).toFixed(2)}
                                %
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="famiglia" title="Famiglia">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-light bg-black border border-dark sticky-top">
                          Famiglia
                        </th>
                        {renderSortableHeader(
                          "Ricerche",
                          "numero_ricerche_articolo",
                          tpFamigliaData,
                          setTpFamigliaData
                        )}
                        {renderSortableHeader(
                          "Netto Totale",
                          "netto_totale",
                          tpFamigliaData,
                          setTpFamigliaData
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tpFamigliaData.map((famiglia, index) => (
                        <tr
                          key={index}
                          onClick={() => handleArticleClick(famiglia)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="text-light bg-black border border-dark fw-bold">
                            {famiglia.famiglia}
                          </td>
                          <td
                            className="bg-black border border-dark"
                            style={{ color: "rgba(241,111,36,1)" }}>
                            {famiglia.numero_ricerche_articolo}
                          </td>
                          <td
                            className="bg-black border border-dark"
                            style={{ color: "rgba(241,111,36,1)" }}>
                            {formatToEuro(famiglia.netto_totale)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>





        <Modal.Footer className="bg-dark">
          {" "}
          <img
            src={logo}
            alt=""
            className="mx-auto p-2"
            style={{ width: "180px" }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default Dettagli;
