import React, { useState, useEffect } from "react";
import NavBarPro from "./proforma/NavBarPro";
import TabellaProforma from "./proforma/TabellaProforma";
import SearchBar from "./proforma/SearchBar";
import FooterMobile from "./proforma/FooterMobilePro";
import ButtonExcel from "./proforma/ButtonExcel"; // Assicurati che il percorso sia corretto
import "../App.css";

const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [proformaData, setProformaData] = useState([]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFetchData = async () => {
    try {
      const res = await fetch("ws/proforma.php");
      const data = await res.json();
      console.log("Risposta dell'API:", data); // Stampa la risposta per conferma
  
      // Accedi direttamente al campo 'TP' che è un array
      if (data && data.TP) {
        setProformaData(data.TP); // Imposta i dati con l'array 'TP'
      } else {
        console.error("Il campo 'TP' non è presente nella risposta");
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    }
  };
  

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="main-container-pro">
      <NavBarPro />
      <div className="d-flex justify-content-between align-items-center me-5 pro-form">
        <SearchBar onSearch={handleSearch} />
        <ButtonExcel data={proformaData} filename="clienti.xlsx" />
        </div>

      <div>
        <TabellaProforma searchQuery={searchQuery} />
      </div>
      <FooterMobile />
    </div>
  );
};

export default Home;
