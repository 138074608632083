import NavbarSR from "./sud_ricambi/NavbarTopSR";
import CardSellSR from "./sud_ricambi/CardSellSR";
import CardSell2SR from "./sud_ricambi/CardSell2SR";
import CardSell3SR from "./sud_ricambi/CardSell3SR";
import CardSell4SR from "./sud_ricambi/CardSell4SR";
import AreaChartSR from "./sud_ricambi/AreaChartSR";
import WorldMapsSR from "./sud_ricambi/WorldMapsSR";
import BarChartSR from "./sud_ricambi/BarChartSR";
import BarChartItSR from "./sud_ricambi/BarChartItSR";
import GraphicDaySR from "./sud_ricambi/GraphicDaySR";
import GraphicWeekSR from "./sud_ricambi/GraphicWeekSR";
import TabsClientSR from "./sud_ricambi/TabsClientSR";
import TabsTipoCliSR from "./sud_ricambi/TabsTipoCliSR";
import FooterMobileSr from "./sud_ricambi/FooterMobileSr";
import GoogleMap from "./sud_ricambi/GoogleMap";

function Home() {
  const apikey = 'AIzaSyD16h98hJe26HkkEvzKPbH2WytgT5srggM';

  return (
    <div className="main-container">
      <NavbarSR/>
      <div className="sud_ricambi"> 
        <GraphicDaySR/>
        <GraphicWeekSR/>
        <GoogleMap apiKey={apikey} />
        <div className="card-container">
          <CardSell3SR/>
          <CardSell4SR/>
        </div>
        <TabsClientSR/>
        <TabsTipoCliSR/>
        <AreaChartSR/>
        <BarChartSR/>
        <BarChartItSR/>
      </div>
      <FooterMobileSr/>
    </div>
  );
}

export default Home;
