import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Card } from "react-bootstrap";
import axios from "axios";
import ClientView from "./ClientViews"


const MapContainer = () => {
  const [citiesData, setCitiesData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const handleCloseModal = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "ws/index.php?Command=google.getAnalitycs"
        );

        if (
          response.data &&
          response.data.TP &&
          response.data.TP.mappa &&
          response.data.TP.mappa.ecommerce
        ) {
          const ecommerceData = response.data.TP.mappa.ecommerce;

          const coordinatesArray = [];
          let totalUsers = 0;

          for (const cityData of ecommerceData) {
            try {
              if (cityData.descrizioneCity) {
                const response = await axios.get(
                  `https://nominatim.openstreetmap.org/search?format=json&q=${cityData.descrizioneCity}`
                );
                const coordinates = response.data[0];

                if (coordinates) {
                  coordinatesArray.push({
                    name: cityData.descrizioneCity,
                    position: {
                      lat: parseFloat(coordinates.lat),
                      lng: parseFloat(coordinates.lon),
                    },
                    visits: parseFloat(cityData.activeUser),
                  });

                  // Aggiungi il numero di visite di questa città al totale degli utenti attivi
                  totalUsers += parseFloat(cityData.activeUser);
                }
              }
            } catch (error) {
              console.error("Error retrieving coordinates:", error);
            }
          }

          setCitiesData(coordinatesArray);
          setTotalActiveUsers(totalUsers); // Imposta il totale degli utenti attivi
        } else {
          console.error("Expected data structure not found in the response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const center = { lat: 42.4046, lng: 12.8631 };

  return (
    <>
   <Card className="hover-effect" style={{ minHeight: "230px", borderRadius: '25px' }} >
  <div
    className="text-titlew mx-auto mt-1"
    style={{
      backgroundColor: 'white',
      padding: "3px",
      borderRadius: "5px",
      width: '25%',
      textAlign: "center", // Ensure text alignment is correct
      cursor: "pointer"
    }}
    onClick={handleShowModal}
  >
    <strong className="text-title">UTENTI ATTIVI:</strong>
    {totalActiveUsers}
  </div>
  
  <LoadScript googleMapsApiKey="AIzaSyD16h98hJe26HkkEvzKPbH2WytgT5srggM">
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100%", borderRadius: '0px 0px 25px 25px' }}
      zoom={1.6}
      center={center}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      }}
    >
      {citiesData.map((city, index) => (
        <Marker
          key={index}
          position={city.position}
          onClick={() => setSelectedCity(city)}
        />
      ))}
      {selectedCity && (
        <InfoWindow
          position={selectedCity.position}
          onCloseClick={() => setSelectedCity(null)}
        >
          <div>
            <h3>{selectedCity.name}</h3>
            <p>Visits: {selectedCity.visits}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  </LoadScript>
</Card>

    <ClientView showModal={showModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default MapContainer;