import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";

function BasicExample() {
  const [sortedTipo_cliData, setSortedTipo_cliData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("./dati_dashboard.json");
        const tipo_cliData = response.data?.TP?.tipo_cli || [];

        const transformedData = tipo_cliData.map(item => ({
          tipo_cliente: item.tipo_cliente,
          valore: parseFloat(item.valore).toFixed(2),
          numero: parseInt(item.numero),
          origine: item.origine,
        }));

        const sortedData = transformedData.sort((a, b) =>
          parseFloat(b.valore) - parseFloat(a.valore)
        );

        setSortedTipo_cliData(sortedData);
      } catch (error) {
        console.error('Error fetching data from the server:', error);
      }
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = "tipo_cli.xlsx";
    const data = sortedTipo_cliData.map((tipo_cliItem) => ({
      Cliente: tipo_cliItem.tipo_cliente,
      Valore: tipo_cliItem.valore,
      Numero: tipo_cliItem.numero,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, fileName);
  };

  return (
    <div className='bg-light widget-client2 ombra tb hover-effect' style={{borderRadius: '25px'}}>
      <Table className='tb border-$gray-200'  style={{ fontSize: '0.8rem'}}>
        <thead>
          <tr>
            <th className='bg-dark text-light sticky-top'>tipo_cli</th>
            <th className='bg-dark text-light sticky-top'>Valore</th>
            <th className='bg-dark text-light sticky-top'>Numero</th>
            <th className="bg-success text-light sticky-top text-center excel" onClick={exportToExcel} style={{width: '6%'}}><SiMicrosoftexcel className="fs-5"/></th>
          </tr>
        </thead>
        <tbody>
          {sortedTipo_cliData.map((tipo_cliItem, index) => (
            <tr key={index}>
              <td>{tipo_cliItem.tipo_cliente}</td>
              <td>{formatToEuro(tipo_cliItem.valore)}</td>
              <td>{tipo_cliItem.numero}</td>
              <td></td>

            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default BasicExample;
